import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';
import BouncePage from 'components/BouncePage';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import Start from 'components/Start';

import { useProfilingStore } from 'services/ProfilingService';

import detectIE from 'utilities/userAgent';

useProfilingStore.getState().init();

const container = document.createElement('div');
container.className = 'application';
document.body.appendChild(container);

function ErrorFallback({ error }) {
  return <ErrorFallbackUI message={error.message} />;
}

export const LANDING_URL = window.location.href;
const showFallBackPage = detectIE();
const fallBack = <BouncePage />;
const app = (
  <>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Start />
    </ErrorBoundary>
  </>
);
const result = showFallBackPage ? fallBack : app;

const root = createRoot(container);
root.render(result);

export default Start;
