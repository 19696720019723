import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Texture, Vector3 } from 'three';
import HUDSpriteMaterial from 'utilities/canvas/HUDSpriteMaterial';
import { useWindowStore } from 'services/WindowService';
import { lgBaseColors } from 'uiLivi/styles';
import { Billboard, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export default function NamePlate({ name, handleClick }) {
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const [hover, setHover] = useState(false);
  useEffect(() => {
    return () => {
      if (hover) setWindowStoreHover(false);
    };
  }, []);

  const position = [0, 1.0, 0];
  const scale = [0.3, 0.4, 0.3];

  return (
    <mesh
      onPointerOver={() => {
        setWindowStoreHover(true);
        setHover(true);
      }}
      onPointerOut={() => {
        setWindowStoreHover(false);
        setHover(false);
      }}
      onClick={handleClick}
      position={position}
      scale={scale}
    >
      <cylinderGeometry attach="geometry" args={[2, 1, 6, 10]} />
      <meshBasicMaterial attach="material" color={'hotpink'} visible={false} />
    </mesh>
  );
}
