import React, { useEffect, useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { ImageLoader, NormalBlending, Sprite, Texture } from 'three';
import HUDSpriteMaterial from 'utilities/canvas/HUDSpriteMaterial';
import { getRandomBetween } from 'utilities/math';
import Like from 'uiLivi/assets/Emojis/emoji_like.png';
import Love from 'uiLivi/assets/Emojis/emoji_love.png';
import Clap from 'uiLivi/assets/Emojis/emoji_clap.png';
import Smile from 'uiLivi/assets/Emojis/emoji_smile.png';
import Celebration from 'uiLivi/assets/Emojis/emoji_celebration.png';
import Greet from 'uiLivi/assets/Emojis/emoji_greet.png';
import { Power2, Sine, TweenMax } from 'gsap';
import { useChatStore } from 'services/ChatService';

const getEmojiSprite = emojiTexture => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;

  const context = canvas.getContext('2d');
  context.drawImage(emojiTexture, 0, 0, 256, 256);

  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const material = new HUDSpriteMaterial();
  material.map = texture;
  material.transparent = true;
  material.depthTest = true;
  material.depthWrite = false;
  material.blending = NormalBlending;

  const sprite = new Sprite(material);
  const scale = 0;
  sprite.scale.set(scale, scale, scale);
  sprite.material.uniforms.opacity.value = 0;

  return sprite;
};

export default function PlayerEmojiFX({ userId }) {
  const [like, love, clap, smile, celebration, greet] = useLoader(ImageLoader, [
    Like,
    Love,
    Clap,
    Smile,
    Celebration,
    Greet,
  ]);
  const emoji = useChatStore(state => state.emoji);
  const emojisRef = useRef(null);

  const getTextureByType = type => {
    switch (type) {
      case 'like':
        return like;
      case 'love':
        return love;
      case 'clap':
        return clap;
      case 'smile':
        return smile;
      case 'celebration':
        return celebration;
      case 'greet':
        return greet;
    }
  };

  const spawnEmoji = emojiTexture => {
    if (emojisRef.current.children.length > 14) return;

    let sprite = getEmojiSprite(emojiTexture);
    emojisRef.current.add(sprite);

    const getSign = () => {
      return Math.random() > 0.5 ? -1 : 1;
    };

    const progress = { value: 0 };
    const duration = 2 + Math.random();
    const direction = getSign();
    const yDestination = getRandomBetween(1, 2);

    TweenMax.to(sprite.scale, 0.25, {
      x: 0.2,
      y: 0.2,
      z: 0.2,
      ease: Power2.easeIn,
    });

    TweenMax.to(sprite.material.uniforms.opacity, 0.25, {
      value: 1,
      ease: Power2.easeIn,
    });

    TweenMax.to(sprite.material.uniforms.opacity, 0.25, {
      value: 0,
      delay: duration - 0.25,
      ease: Power2.easeIn,
    });
    TweenMax.to(sprite.scale, 0.25, {
      delay: duration - 0.25,
      x: 0.3,
      y: 0.3,
      z: 0.3,
      ease: Power2.easeIn,
    });

    TweenMax.to(progress, duration, {
      value: 1,
      onUpdate: () => {
        const wave = Math.sin(6 * progress.value);
        sprite.position.x = direction * wave * 0.25;
        sprite.position.y = progress.value * yDestination;
        sprite.material.uniforms.rotation.value = -wave * 0.25;
      },
      onComplete: () => {
        if (emojisRef.current) {
          sprite.material.uniforms.map.value.dispose();
          sprite.material.dispose();
          emojisRef.current.remove(sprite);
          sprite = null;
        }
        sprite = null;
      },
      ease: Sine.easeOut,
    });
  };

  useEffect(() => {
    if (emoji != null && emoji.userId === userId) spawnEmoji(getTextureByType(emoji.emoji));
  }, [emoji]);

  return <group name="EmojiFX" ref={emojisRef} position={[0, 1.75, 0]} />;
}
