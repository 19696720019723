import styled from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';

export const InstructionVisualContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 50px;
  flex-wrap: nowrap;
  justify-content: center;
`;

export const InstructionVisualColumn = styled.div`
  width: 150px;
  position: relative;
`;

export const SpacerColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const InstructionVisualBox = styled.div`
  padding-top: 80%;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    transform: translateY(-50%);
  }
`;

export const InstructionSpacerBox = styled.div`
  background-color: transparent;
  color: ${lgBaseColors.grey.medium};
  padding: 0 20px;
  line-height: 100%;
  white-space: nowrap;
`;

export const InstructionVisualCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: -35px;
  padding: 10px;
  color: ${lgBaseColors.grey.medium};
`;
