import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { useContentStore } from 'services/ContentService';
import { useDeviceStore } from 'services/DeviceService';
import { Howler } from 'howler';

export const useSoundStore = create((set, get) => ({
  isPlayAllowed: false,
  enabled: userStore.getSoundEnabled(),
  videos: [],

  init: () => {
    const autoPlay = () => {
      window.removeEventListener('touchstart', autoPlay);
      window.removeEventListener('mousedown', autoPlay);
      window.removeEventListener('keydown', autoPlay);
      get().allowPlay();
    };
    window.addEventListener('touchstart', autoPlay);
    window.addEventListener('mousedown', autoPlay);
    window.addEventListener('keydown', autoPlay);

    useContentStore.subscribe(() => {
      get().adjustVolume();
    });
  },

  allowPlay: () => {
    set({ isPlayAllowed: true });
    get().videos.forEach(video => {
      if (video.video) {
        try {
          video.play();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    });
    get().unmute();
  },

  unmute: () => {
    const isDesktop = !useDeviceStore.getState().device.isMobile;
    if (isDesktop) {
      get().videos.forEach(video => {
        video.muted = false;
      });
    }
  },

  playVideo: (video, position) => {
    const gain = Howler.ctx.createGain();
    gain.gain.setValueAtTime(0.5, Howler.ctx.currentTime);
    gain.connect(Howler.masterGain);

    const panner = Howler.ctx.createPanner();
    panner.coneInnerAngle = 360;
    panner.coneOuterAngle = 360;
    panner.coneOuterGain = 0;
    panner.distanceModel = 'exponential';
    panner.maxDistance = 10000;
    panner.refDistance = 20;
    panner.rolloffFactor = 10;
    panner.panningModel = 'HRTF';
    panner.positionX.setValueAtTime(position.x, Howler.ctx.currentTime);
    panner.positionY.setValueAtTime(position.y, Howler.ctx.currentTime);
    panner.positionZ.setValueAtTime(position.z, Howler.ctx.currentTime);
    panner.connect(gain);

    const source = Howler.ctx.createMediaElementSource(video);
    source.connect(panner);

    const videos = get().videos;
    videos.push(video);
    set({ videos });
    video.play();

    if (get().isPlayAllowed) {
      get().unmute();
    }
    get().adjustVolume();
  },

  removeVideo: video => {
    const videos = get().videos;
    const videoIndex = videos.indexOf(video);
    if (videoIndex > -1) {
      videos.splice(videoIndex, 1); // 2nd parameter means remove one item only
    }
    set({ videos });
  },

  setEnabled: active => {
    set({ enabled: active });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  toggle: () => {
    set({ enabled: !get().enabled });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  adjustVolume: () => {
    const { videos } = get();
    videos.forEach(video => {
      if (video) {
        if (useContentStore.getState().activeContent) {
          video.volume = 0;
        } else {
          video.volume = get().enabled ? 1 : 0;
        }
      }
    });
  },
}));
