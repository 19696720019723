import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';

import { useDistrictStore } from 'services/DistrictService';
import { useOnboardingStore } from 'services/OnboardingService';
import { useUserStore } from 'services/UserService';
import { useDebugStore } from 'storage/debug';
import Notifications from '../Notifications';
import Twilio from 'components/Twilio';
import { useEventStore } from 'services/EventService';
import useDebugHotkeys from 'components/Play/useDebugHotkeys';
import PlayDistrict from 'components/Play/PlayDistrict';
import OverlayInterface from 'uiVe/components/OverlayInterface';

// mounted & unmounted by Routes
// has event & login
export default function Play() {
  const { mapId } = useParams();

  const districtIdList = useDistrictStore(state => state.getSimpleDistrictList().map(d => d.id));
  const showUI = useDebugStore(state => !state.getUiDisabled());
  const userId = useUserStore(state => state.user.id);
  const userInitials = useUserStore(
    state => state.user.forename[0].toUpperCase() + state.user.surname[0].toUpperCase()
  );

  useDebugHotkeys();
  useEffect(() => useOnboardingStore.getState().startOnboarding(), []);

  if (!districtIdList.includes(mapId)) {
    // eslint-disable-next-line no-console
    console.warn('redirect in play', districtIdList, mapId);
    const startDistrict = useEventStore.getState().event.districts?.[0]?.room;
    return <Redirect to={`/play/${startDistrict}`} />;
  }

  const { uiVe } = useDebugStore(state => state.getUi());

  return (
    <>
      {uiVe && <OverlayInterface />}
      <PlayDistrict mapId={mapId} />
      {showUI && <Notifications />}
      <Twilio identity={userId} userInitials={userInitials} />
    </>
  );
}
