import React, { useRef } from 'react';
import AnimationController from 'components/Play/Character/AnimationController';
import { globalPlayers, useGlobalHubStore } from 'services/GlobalHubService';
import * as GLM from 'gl-matrix';
import { interpolatePosRot } from 'utilities/physics';
import { DEFAULT_APPEARANCE } from 'components/Play/GlobalPlayers/consts';

const GlobalPlayer = ({ userId }) => {
  const group = useRef();
  const scale = 1.0;

  const pos = useRef(null);
  const playerData = globalPlayers[userId];
  const { appearance } = playerData;

  return (
    <group ref={group}>
      <AnimationController
        group={group}
        isSelf={false}
        getPlayerData={dt => {
          const playerData = globalPlayers[userId];
          const { position, velocity, rotation } = playerData;
          const pNet = GLM.vec3.fromValues(...position);
          if (!pos.current) {
            pos.current = pNet;
          }
          const pCurrent = GLM.vec3.fromValues(...pos.current);
          const dist = GLM.vec3.dist(pCurrent, pNet);
          if (dist > 10) {
            GLM.vec3.copy(pCurrent, pNet);
          }

          const vel = GLM.vec3.fromValues(...velocity);
          const res = interpolatePosRot(pCurrent, rotation, pNet, rotation, vel, dt);
          pos.current = res.position;
          return {
            ...playerData,
            position: res.position,
          };
        }}
        scale={[scale, scale, scale]}
        reactionId={0}
        appearance={appearance || DEFAULT_APPEARANCE}
      />
    </group>
  );
};

export default function GlobalPlayers() {
  const userIds = useGlobalHubStore(state => state.userIds);

  return userIds.map((userId, i) => <GlobalPlayer userId={userId} key={i} />);
}
