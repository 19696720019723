import { Container, OptionPanelColumn } from './styles';
import React, { useEffect } from 'react';
import CategoryPanel from './CategoryPanel';
import OptionsPanel from './OptionsPanel';
import { useAvatarStore } from 'services/AvatarService';
import { getInitialCustomizationConfiguration } from 'services/AvatarService/config';
import { getRandomBetween } from 'utilities/math';
import { useWindowStore } from 'services/WindowService';
import { useDeviceStore } from 'services/DeviceService';
import { useUserStore } from 'services/UserService';

export function CustomizationUi({ initial, onDone }) {
  const customizationConfiguration = useAvatarStore(state => state.customizationConfiguration);
  const selectedCategory = customizationConfiguration.find(ao => ao.selected);
  const selectCustomizationOption = useAvatarStore(state => state.selectCustomizationOption);
  const initialConfig = getInitialCustomizationConfiguration();
  const isMobileBreakpoint = useWindowStore(state => state.isMobile);
  const isMobileOnly = useDeviceStore(state => state.device.isMobileOnly);
  const simplify = isMobileOnly || isMobileBreakpoint;
  const appearance = useUserStore(state => state.player.user.appearance);

  const getConfigurationFromAppearance = () => {
    customizationConfiguration.forEach(category => {
      category.customized = true;
      category.optionPanels.forEach(optionPanels => {
        optionPanels.selections.forEach(selection => {
          const isPrimaryOrSecondary = selection.name === 'jacket' || selection.name === 'shirt';
          if (!isPrimaryOrSecondary) {
            selection.index =
              appearance[category.id][optionPanels.type] === undefined
                ? 0
                : appearance[category.id][optionPanels.type]['default'];
          } else {
            if (selection.name === 'jacket') selection.index = appearance[category.id][optionPanels.type]['jacket'];
            if (selection.name === 'shirt') selection.index = appearance[category.id][optionPanels.type]['shirt'];
          }
        });
      });
    });

    return customizationConfiguration;
  };

  useEffect(() => {
    return () => {
      useAvatarStore.getState().deselectCustomizationCategories();
    };
  }, []);

  const randomize = () => {
    initialConfig.forEach(category => {
      const categoryId = category.id;
      category.optionPanels.forEach((optionPanel, i) => {
        const index_start = 0;
        const index_end = optionPanel.options.length - (categoryId === 'hair' && i === 0 ? 2 : 1);
        selectCustomizationOption(categoryId, i, getRandomBetween(index_start, index_end));
        if (categoryId === 'top' && i === 1) {
          selectCustomizationOption(categoryId, i, getRandomBetween(index_start, index_end), 1);
        }
      });
    });
  };

  // start with randomized character
  useEffect(() => {
    if (!appearance) randomize();
  }, []);

  return (
    <Container>
      <CategoryPanel
        simplify={simplify}
        customizationConfiguration={initial ? customizationConfiguration : getConfigurationFromAppearance()}
        onDone={onDone}
        randomize={randomize}
      />
      {!simplify && (
        <OptionPanelColumn>
          {selectedCategory &&
            selectedCategory.optionPanels.map((op, opIndex) => {
              return (
                <OptionsPanel
                  key={selectedCategory.id + opIndex}
                  categoryId={selectedCategory.id}
                  panelIndex={opIndex}
                  panel={op}
                />
              );
            })}
        </OptionPanelColumn>
      )}
    </Container>
  );
}
