import styled, { css } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { mediaMax } from 'common/layout';
import { hexToRgba } from 'utilities/color';

const backgroundStyle = css`
  background-color: ${hexToRgba(colors.background.base, 0.85)};
  backdrop-filter: blur(4px);
`;

export const ContentContainer = styled.div`
  padding: 20px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  ${backgroundStyle};
  color: ${colors.font.light};
  min-height: 100%;
  ${mediaMax(
    'small',
    css`
      width: 100vw;
    `
  )}
`;
