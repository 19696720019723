import { useEffect } from 'react';
import { useDebugStore } from 'storage/debug';
import { isKeyPressed } from 'utilities/keyboard';

export default function useDebugHotkeys() {
  useEffect(() => {
    const debugStore = useDebugStore.getState();
    const keydown = e => {
      if (!debugStore.getDeveloperContentEnabled()) {
        return;
      }
      if (isKeyPressed('control')) {
        if (e.key === '1') {
          const disabled = debugStore.getUiDisabled();
          debugStore.setUiDisabled(!disabled);
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === '3') {
          const enabled = debugStore.getFlyCamEnabled();
          debugStore.setFlyCamEnabled(!enabled);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };
    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);
}
