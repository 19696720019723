import styled, { css } from 'styled-components';
import { hexToRgba } from 'utilities/color';
import { colors } from 'uiLivi/styles';
import { H3 } from 'common/text';
import { mediaMax } from 'common/layout';

const backgroundStyle = css`
  background-color: ${hexToRgba(colors.background.base, 0.85)};
  backdrop-filter: blur(4px);
`;

export const ContentContainer = styled.div`
  padding: 20px;
  text-align: center;
  ${backgroundStyle};
  color: ${colors.font.light};
  min-height: 100%;
  ${mediaMax(
    'small',
    css`
      width: 100vw;
    `
  )}
`;

export const TitleContainer = styled.div`
  position: relative;
  ${backgroundStyle};
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  color: ${colors.font.light};
`;

export const Title = styled(H3)`
  line-height: inherit;
  margin-top: 0;
  text-align: center;
`;

export const InstructionParagraph = styled.p`
  max-width: 240px;
  display: inline-block;
  line-height: 22px;
  margin-top: 0;
`;
