import { usePostStore } from 'three/Renderer/store';
import { useThree } from '@react-three/fiber';

import { CameraHelper, Color, DirectionalLight, PCFSoftShadowMap } from 'three';
import React, { useEffect, useMemo } from 'react';
import { useEnvironmentStore } from '../store';

export function renderShadowOnce(gl, scene, camera) {
  gl.shadowMap.needsUpdate = true;
  const players = scene.getObjectByName('Players');
  const products = scene.getObjectByName('Products');
  players && (players.visible = false);
  products && (products.visible = false);
  gl.render(scene, camera);
  players && (players.visible = true);
  products && (products.visible = true);
}

export default function Lights() {
  const env = useEnvironmentStore(state => state.environmentConfiguration);
  const shadow = usePostStore(state => state.renderConfiguration).shadow;
  const { gl, camera, scene } = useThree();
  gl.shadowMap.type = PCFSoftShadowMap;

  const dirLightWithShadow = useMemo(() => {
    return new DirectionalLight();
  }, []);

  const showShadowCameraHelper = false;
  if (showShadowCameraHelper) {
    useEffect(() => {
      const helper = new CameraHelper(dirLightWithShadow.shadow.camera);
      scene.add(helper);
      return () => {
        scene.remove(helper);
      };
    }, []);
  }

  dirLightWithShadow.intensity = env.dirLightWithShadow.intensity;
  dirLightWithShadow.color = new Color(env.dirLightWithShadow.color);
  dirLightWithShadow.name = 'dirLightWithShadow';

  const { sky } = env;

  const distance = 1000;
  const x = distance * Math.sin(sky.inclination) * Math.cos(sky.azimuth);
  const y = distance * Math.cos(sky.inclination);
  const z = distance * Math.sin(sky.inclination) * Math.sin(sky.azimuth);
  dirLightWithShadow.position.set(Math.round(x), Math.round(y), Math.round(z));

  gl.shadowMap.enabled = true;
  gl.shadowMap.autoUpdate = shadow.enabled;

  useEffect(() => {
    if (!shadow.enabled) {
      renderShadowOnce(gl, scene, camera);
    }
  }, [shadow.enabled]);

  dirLightWithShadow.castShadow = true;
  const size = 60;
  dirLightWithShadow.shadow.camera.near = 0;
  dirLightWithShadow.shadow.camera.far = 2000;
  dirLightWithShadow.shadow.camera.left = -size;
  dirLightWithShadow.shadow.camera.right = size;
  dirLightWithShadow.shadow.camera.bottom = -size;
  dirLightWithShadow.shadow.camera.top = size;
  dirLightWithShadow.shadow.camera.updateProjectionMatrix();
  dirLightWithShadow.shadow.bias = shadow.bias / 100000;
  dirLightWithShadow.shadow.mapSize.width = shadow.size;
  dirLightWithShadow.shadow.mapSize.height = shadow.size;

  if (dirLightWithShadow.shadow.map && dirLightWithShadow.shadow.map.width !== shadow.size) {
    dirLightWithShadow.shadow.map.setSize(shadow.size, shadow.size);

    if (!shadow.enabled) {
      renderShadowOnce(gl, scene, camera);
    }
  }

  return (
    <>
      <primitive object={dirLightWithShadow} />
    </>
  );
}
