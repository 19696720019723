import React, { useEffect, useRef, useState } from 'react';
import { useChatStore } from 'services/ChatService';
import SendIcon from 'uiLivi/components/Chat/Icons/Send.svg';
import { Container, SendButton, TextArea } from './style';
import { useDeviceStore } from 'services/DeviceService';
import { useTranslate } from 'services/TranslationService/hooks';

const ChatInput = ({ maxCharacters, expandAfterCharacters, isContent }) => {
  const [input, setInput] = useState('');
  const send = useChatStore(state => state.send);
  const isLargeInput = useChatStore(state => state.isLargeInput);
  const setLargeInput = useChatStore.getState().setLargeInput;
  const { setKeyboardActive } = useDeviceStore.getState();
  const translate = useTranslate();

  const textArea = useRef();

  const handleChange = e => {
    if (e.nativeEvent.inputType === 'insertLineBreak') {
      return;
    }
    const input = e.nativeEvent.target.value.substring(0, maxCharacters);
    setInput(input);
  };

  useEffect(() => {
    setLargeInput(input.length > expandAfterCharacters);
  }, [input]);

  const submitOnEnter = e => {
    if (e.which === 13) {
      e.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
      e.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
    }
  };

  const submitInput = e => {
    setInput('');
    e.preventDefault();
    if (input === '') return false;
    send(input);
    textArea.current.focus();
    return true;
  };

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };

  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  return (
    <Container>
      <TextArea
        ref={textArea}
        value={input}
        rows={isLargeInput ? 4 : 1}
        onChange={handleChange}
        onKeyUp={submitOnEnter}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        placeholder={translate('chat.room.placeholder') || 'Say something...'}
        form={'inputForm'}
        isContent={isContent}
      />
      <form id="inputForm" onSubmit={submitInput}>
        <SendButton expanded={isLargeInput} type="submit">
          <SendIcon />
        </SendButton>
      </form>
    </Container>
  );
};

export default ChatInput;
