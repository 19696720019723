import create from 'utilities/zustand/create';
import userStorage from 'storage/user';
import { useModalService } from 'services/ModalService';
import { useDeviceStore } from 'services/DeviceService';
import ControlOnboarding from 'components/Onboarding/ControlOnboarding';
import ProfileOnboarding from 'components/Onboarding/ProfileOnboarding';
import SoundOnboarding from 'components/Onboarding/SoundOnboarding';
import { useSoundStore } from 'services/SoundService';
import { useEventStore } from 'services/EventService';

export const useOnboardingStore = create((set, get) => ({
  done: userStorage.getOnboardingDone(),
  startOnboarding: () => {
    if (!get().done) {
      get().repeatOnboarding();
    }
  },
  repeatOnboarding: () => {
    const useAvatars = useEventStore.getState().event.useAvatars;
    useAvatars && useModalService.getState().addModal(<ControlOnboarding key="controls" />);
    useModalService.getState().addModal(<ProfileOnboarding key="profile" />);
    useModalService.getState().addModal(<SoundOnboarding key="sound" />);
  },

  finishOnboarding: () => {
    userStorage.setOnboardingDone();
    set({ done: true });
  },
}));
