import create from 'utilities/zustand/create';
import { Raycaster, Vector3 } from 'three';

const areaRaycaster = new Raycaster();

export const useAreaStore = create((set, get) => ({
  areas: [],
  getArea: (position, areaGroup) => {
    if (!position || !areaGroup) {
      return null;
    }
    areaRaycaster.set(
      new Vector3(position[0], position[1], position[2]).add(new Vector3(0, 1, 0)),
      new Vector3(0, -1, 0)
    );
    const results = areaRaycaster.intersectObject(areaGroup, true);
    if (results.length > 0) {
      return results[0].object.name.replace('_area', '');
    } else {
      return null;
    }
  },
}));
