import {
  Box3,
  BoxGeometry,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Plane,
  Vector3,
} from 'three';

function clipGeometry(vertexes, position, size, plane) {
  const projectorMatrix = new Matrix4();
  projectorMatrix.setPosition(position);
  const projectorMatrixInverse = new Matrix4().copy(projectorMatrix).invert();

  const inVertices = vertexes.map(v => {
    const result = v.clone();
    result.applyMatrix4(projectorMatrixInverse);
    return result;
  });
  const outVertices = [];

  const s = 0.5 * Math.abs(size.dot(plane));

  // a single iteration clips one face,

  function clip(v0, v1, p, s) {
    const d0 = v0.dot(p) - s;
    const d1 = v1.dot(p) - s;
    const s0 = d0 / (d0 - d1);
    const v = new Vector3(v0.x + s0 * (v1.x - v0.x), v0.y + s0 * (v1.y - v0.y), v0.z + s0 * (v1.z - v0.z));
    return v;
  }

  for (let i = 0; i < inVertices.length; i += 3) {
    let total = 0;
    let nV1, nV2, nV3, nV4;

    const d1 = inVertices[i + 0].dot(plane) - s;
    const d2 = inVertices[i + 1].dot(plane) - s;
    const d3 = inVertices[i + 2].dot(plane) - s;

    const v1Out = d1 > 0;
    const v2Out = d2 > 0;
    const v3Out = d3 > 0;

    // calculate, how many vertices of the face lie outside of the clipping plane

    total = (v1Out ? 1 : 0) + (v2Out ? 1 : 0) + (v3Out ? 1 : 0);

    switch (total) {
      case 0: {
        // the entire face lies inside of the plane, no clipping needed

        outVertices.push(inVertices[i]);
        outVertices.push(inVertices[i + 1]);
        outVertices.push(inVertices[i + 2]);
        break;
      }

      case 1: {
        // one vertex lies outside of the plane, perform clipping

        if (v1Out) {
          nV1 = inVertices[i + 1];
          nV2 = inVertices[i + 2];
          nV3 = clip(inVertices[i], nV1, plane, s);
          nV4 = clip(inVertices[i], nV2, plane, s);
        }

        if (v2Out) {
          nV1 = inVertices[i];
          nV2 = inVertices[i + 2];
          nV3 = clip(inVertices[i + 1], nV1, plane, s);
          nV4 = clip(inVertices[i + 1], nV2, plane, s);

          outVertices.push(nV3);
          outVertices.push(nV2.clone());
          outVertices.push(nV1.clone());

          outVertices.push(nV2.clone());
          outVertices.push(nV3.clone());
          outVertices.push(nV4);
          break;
        }

        if (v3Out) {
          nV1 = inVertices[i];
          nV2 = inVertices[i + 1];
          nV3 = clip(inVertices[i + 2], nV1, plane, s);
          nV4 = clip(inVertices[i + 2], nV2, plane, s);
        }

        outVertices.push(nV1.clone());
        outVertices.push(nV2.clone());
        outVertices.push(nV3);

        outVertices.push(nV4);
        outVertices.push(nV3.clone());
        outVertices.push(nV2.clone());

        break;
      }

      case 2: {
        // two vertices lies outside of the plane, perform clipping

        if (!v1Out) {
          nV1 = inVertices[i].clone();
          nV2 = clip(nV1, inVertices[i + 1], plane, s);
          nV3 = clip(nV1, inVertices[i + 2], plane, s);
          outVertices.push(nV1);
          outVertices.push(nV2);
          outVertices.push(nV3);
        }

        if (!v2Out) {
          nV1 = inVertices[i + 1].clone();
          nV2 = clip(nV1, inVertices[i + 2], plane, s);
          nV3 = clip(nV1, inVertices[i], plane, s);
          outVertices.push(nV1);
          outVertices.push(nV2);
          outVertices.push(nV3);
        }

        if (!v3Out) {
          nV1 = inVertices[i + 2].clone();
          nV2 = clip(nV1, inVertices[i], plane, s);
          nV3 = clip(nV1, inVertices[i + 1], plane, s);
          outVertices.push(nV1);
          outVertices.push(nV2);
          outVertices.push(nV3);
        }

        break;
      }

      case 3: {
        // the entire face lies outside of the plane, so let's discard the corresponding vertices

        break;
      }
    }
  }

  return outVertices.map(o => {
    o.applyMatrix4(projectorMatrix);
    return o;
  });
}

class Cell {
  constructor(space, vertexes) {
    this.space = space;
    this.vertexes = vertexes;
    this.children = [];
  }

  split(depth) {
    const bb = this.space;
    function a(d) {
      return [bb.min[d], bb.min[d] + (bb.max[d] - bb.min[d]) / 2, bb.max[d]];
    }
    const xa = a('x');
    const ya = a('y');
    const za = a('z');

    const v0 = new Vector3();
    const v1 = new Vector3();
    const plane = new Vector3();

    for (let x = 0; x < 2; x++) {
      const bbx = new Box3(new Vector3(xa[x], ya[0], za[0]), new Vector3(xa[x + 1], ya[2], za[2]));
      const xVertexes = clipGeometry(
        this.vertexes,
        bbx.getCenter(v0),
        bbx.getSize(v1),
        plane.set(x === 0 ? 1 : -1, 0, 0)
      );

      for (let y = 0; y < 2; y++) {
        const bby = new Box3(new Vector3(xa[x], ya[y], za[0]), new Vector3(xa[x + 1], ya[y + 1], za[2]));
        const yVertexes = clipGeometry(
          xVertexes,
          bby.getCenter(v0),
          bby.getSize(v1),
          plane.set(0, y === 0 ? 1 : -1, 0)
        );

        for (let z = 0; z < 2; z++) {
          const bbz = new Box3(new Vector3(xa[x], ya[y], za[z]), new Vector3(xa[x + 1], ya[y + 1], za[z + 1]));
          const zVertexes = clipGeometry(
            yVertexes,
            bbz.getCenter(v0),
            bbz.getSize(v1),
            plane.set(0, 0, z === 0 ? 1 : -1)
          );
          if (zVertexes.length > 0) {
            const cell = new Cell(bbz, zVertexes);
            this.children.push(cell);
            if (depth > 0) {
              cell.split(depth - 1);
            }
          }
        }
      }
    }
    this.vertexes = [];
  }
}

export default class Octree extends Object3D {
  constructor(originalMesh, depth, debug) {
    super();
    this.originalMesh = originalMesh;
    this.depth = depth;
    this.debug = debug;
    this.transform();
    this.precalculatePlanes(this.root);
    this.name = 'Octree';
  }

  transform() {
    const startMs = Date.now();

    //compute first space
    const og = this.originalMesh.geometry;
    og.computeBoundingBox();
    const bb = og.boundingBox;
    const center = bb.getCenter(new Vector3());
    const size = bb.getSize(new Vector3());
    const longestSize = Math.max(size.x, Math.max(size.y, size.z));
    const space = new Box3(center.clone().subScalar(longestSize / 2), center.clone().addScalar(longestSize / 2));

    //compute first vertexes
    const positionAttribute = og.attributes.position;
    const index = og.index;
    const vertexes = [];
    for (let i = 0; i < index.count; i++) {
      const vertex = new Vector3();
      vertex.fromBufferAttribute(positionAttribute, index.getX(i));
      vertexes.push(vertex);
    }

    this.root = new Cell(space, vertexes);
    this.root.split(this.depth);

    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(Date.now() - startMs);
      this.showDebug(this.root);
    }
  }

  precalculatePlanes(cell) {
    // planes contain normals
    const planes = [];
    for (let f = 0; f < cell.vertexes.length; f += 3) {
      const vA = cell.vertexes[f];
      const vB = cell.vertexes[f + 1];
      const vC = cell.vertexes[f + 2];
      const plane = new Plane().setFromCoplanarPoints(vA, vB, vC);
      planes.push(plane);
    }
    cell.planes = planes;

    const children = cell.children;
    for (let i = 0, l = children.length; i < l; i++) {
      this.precalculatePlanes(children[i]);
    }
  }

  showDebug(cell) {
    this.debugCell(cell);
    cell.children.forEach(c => {
      this.showDebug(c);
    });
  }

  debugCell(cell) {
    const { space, vertexes } = cell;

    const color = new Color(Math.random(), Math.random(), Math.random());
    const box = new Mesh(new BoxGeometry(1, 1, 1), new MeshBasicMaterial({ wireframe: true, color }));
    box.scale.copy(space.getSize(box.scale));
    box.position.copy(space.getCenter(box.position));
    // this.add(box);

    const positionAttribute = new Float32BufferAttribute(
      vertexes.reduce((acc, v) => {
        acc.push(v.x);
        acc.push(v.y);
        acc.push(v.z);
        return acc;
      }, []),
      3
    );
    const geom = new BufferGeometry();
    geom.setAttribute('position', positionAttribute);
    geom.computeVertexNormals();
    const original = new Mesh(geom, new MeshStandardMaterial({ color }));
    this.add(original);
  }
}
