import styled from 'styled-components';
import fromCdn from 'utilities/cdn';

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const VideoBackground = () => {
  return (
    <StyledVideo autoPlay loop muted poster={fromCdn('/shared/ve-loginbg_poster.jpg')}>
      <source src={fromCdn('/shared/ve-loginbg.mp4')} type="video/mp4" />
    </StyledVideo>
  );
};

export default VideoBackground;
