import { Suspense } from 'react';
import { useDatGuiStackStore } from './store';
import './index.css';
import { zIndex } from 'uiLivi/styles';

function Loader() {
  return <div style={{ backgroundColor: '#333', color: '#fff' }}>Loading...</div>;
}

function CloseButton({ selectedGui }) {
  return (
    <div
      style={{
        fontFamily: 'sans-serif',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
        textAlign: 'right',
        backgroundColor: 'rgba(0,0,0,0.85)',
        backdropFilter: 'blur(5px)',
        padding: 5,
        fontSize: 12,
      }}
    >
      <span style={{ padding: 4, fontWeight: 'bold' }}>{selectedGui.label}</span>
      <span
        style={{ padding: 4, backgroundColor: 'rgba(255,255,255,0.1)', cursor: 'pointer' }}
        onClick={() => {
          useDatGuiStackStore.getState().setSelectedIndex(0);
        }}
      >
        Close
      </span>
    </div>
  );
}
export default function DatGuiStack() {
  const selectedGui = useDatGuiStackStore(state => state.stack.find((_, i) => i === state.selectedIndex));
  return (
    <div style={{ position: 'fixed', height: '100%', top: 0, right: 0, zIndex: zIndex.datGui, overflow: 'auto' }}>
      {selectedGui?.gui && <CloseButton selectedGui={selectedGui} />}
      <Suspense fallback={<Loader />}>{selectedGui && selectedGui.gui}</Suspense>
    </div>
  );
}
