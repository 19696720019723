import styled, { css } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { mediaMax, mediaMin } from 'common/layout';
import { IconContainer, Item } from 'uiLivi/components/Menu/MenuItem/styles';

export const MenuItemContainer = styled.div`
  ${mediaMin(
    'medium',
    css`
      width: 66px;
      margin: 0 auto;
    `
  )}
  ${mediaMax(
    'small',
    css`
      ${Item} {
        background-color: ${colors.background.base};
        display: flex;
        justify-content: center;
      }
      ${IconContainer} {
        margin: 0;
      }
    `
  )}
`;
