import create from 'utilities/zustand/create';
import { useUserStore } from 'services/UserService';
import { useZoneStore } from 'services/ZoneService';
import { interpolatePosRot, simulatePlayerMovement } from 'utilities/physics';
import userStorage from 'storage/user';
import RefinedRelativeControls from 'services/PlayerService/controls/RefinedRelativeControls';
import { useSceneStore } from 'services/SceneService';
import { MODE_FITTING } from './constants';
import { useDebugStore } from 'storage/debug';
import { useAreaStore } from 'services/AreaService';
import { useEventStore } from '../EventService';
import { getSpawnInfo } from './getSpawnInfo';
import { DEFAULT_TELEPORT_DELAY } from 'components/Play/Character/ConfigAnimations';

let lastSendMs = 0;
let socket = null;
let initTimeout = null;

export const usePlayerStore = create((set, get) => ({
  playerIds: [],
  players: {},

  //non network vars
  selectedPlayer: null, // used by the reaction wheel
  position: [0, 0, 0],
  rotation: 0, // rotation around the y axis in radians
  velocity: [0, 0, 0, 0], // [x, y, z, rotation]
  input: [0, 0, 0, 0], // accumulated input for this frame: [x, y, z, rotation]
  reaction: 0,
  zone: null,
  area: null,
  dirty: false,
  controlLock: null,
  controlSchemeId: 'refinedRelativeControls',
  controlSchemes: {
    refinedRelativeControls: RefinedRelativeControls,
  },
  isLookingAtFairPassOfUserId: null,
  mode: null,
  previousRoom: null,
  setMode: newMode => {
    const { mode } = get();
    if (newMode === mode) {
      return;
    }
    if (newMode === MODE_FITTING || mode === MODE_FITTING) {
      socket.emit('player/fittingUpdate', newMode === MODE_FITTING);
    }
    set({ mode: newMode });
  },
  setControlScheme: id => {
    const { initControlScheme } = get();
    set({ controlSchemeId: id });
    initControlScheme();
    userStorage.setControlSchemaId(id);
  },
  initControlScheme: () => {
    const { controlSchemeId, controlSchemes } = get();
    controlSchemes[controlSchemeId].init();
  },
  restoreControlScheme: () => {
    const { controlSchemes } = get();
    const controlSchemeId = userStorage.getControlSchemaId();
    if (controlSchemeId in controlSchemes) {
      set({ controlSchemeId });
    }
  },

  resetAndSpawn: () => {
    const spawnInfo = getSpawnInfo();
    const { position, rotation } = spawnInfo;
    const player = useUserStore.getState().player;
    const { id, user } = player;

    set({
      blockInput: true,
      playerIds: [id],
      players: {
        [id]: {
          position,
          rotation,
          velocity: [0, 0, 0, 0],
          reaction: -1,
          user,
        },
      },
      reaction: -1,
      position,
      rotation,
    });
  },

  enterDistrict: () => {
    const { initControlScheme, position, rotation } = get();

    // setTimeout(() => {
    socket.emit('player/init', { position: [...position, rotation], reaction: 10 }, () => {
      set({ blockInput: false });
    });

    const player = useUserStore.getState().player;
    const { id } = player;
    get().players[id].reaction = 10;
    set({ reaction: 10 });
    initControlScheme();

    //clear teleport reaction
    clearTimeout(initTimeout);
    initTimeout = setTimeout(() => {
      if (get().reaction === 10) {
        get().playerActions.setReaction(0);
      }
    }, 2000);
  },

  leaveDistrict: () => {
    set({
      blockInput: true,
      playerIds: [],
      players: {},
    });
  },

  init: managedSocket => {
    socket = managedSocket;

    const { restoreControlScheme } = get();
    restoreControlScheme();

    const addChange = (state, update) => {
      const id = update.id;
      state.playerIds.push(id);
      const position = update.position.slice(0, 3);
      const rotation = update.position[3];
      const reaction = update.reaction;
      const user = { ...update.user, isSelf: false };
      const velocity = [0, 0, 0, 0];
      state.players[id] = { id, position, rotation, reaction, user, velocity };
    };

    const removeChange = (state, update) => {
      const id = update.id;
      const index = state.playerIds.findIndex(value => value === id);
      state.playerIds.splice(index, 1);
      delete state.players[id];
    };

    const positionChange = (state, update) => {
      const id = update.id;
      const player = state.players[id];
      if (player) {
        player.targetRotation = update.position[3];
        player.targetPosition = update.position.slice(0, 3);
      }
    };

    const reactionChange = (state, update) => {
      const id = update.id;
      const player = state.players[id];
      if (player) {
        player.reaction = update.reaction;
      }
    };

    const userChange = (state, update) => {
      const id = update.id;
      const player = state.players[id];
      if (player) {
        player.user = { ...player.user, ...update.user };
      }
    };

    socket.on('player/update', update => {
      if (get().blockInput) return;
      const all = [
        ...update.adds.map(u => {
          return { ...u, exec: addChange };
        }),
        ...update.removes.map(u => {
          return { ...u, exec: removeChange };
        }),
        ...update.positions.map(u => {
          return { ...u, exec: positionChange };
        }),
        ...update.reactions.map(u => {
          return { ...u, exec: reactionChange };
        }),
        ...update.users.map(u => {
          return { ...u, exec: userChange };
        }),
      ];
      all.sort((u1, u2) => u1.index - u2.index);
      const state = get();
      all.forEach(update => {
        update.exec(state, update);
      });
      if (update.adds.length > 0 || update.removes.length > 0) {
        set({ playerIds: [...state.playerIds] });
      } else {
        set({});
      }
    });

    socket.on('player/updatePositions', positions => {
      if (get().blockInput) return;
      const all = [
        ...positions.map(u => {
          return { ...u, exec: positionChange };
        }),
      ];
      all.sort((u1, u2) => u1.index - u2.index);
      const state = get();
      all.forEach(update => {
        update.exec(state, update);
      });
      set({});
    });
  },

  playerActions: {
    simulate: () => {
      const { collisionMesh, zoneGroup, areaGroup } = useSceneStore.getState().scene;
      const playerData = get();
      const { position: oldPosition, rotation: oldRotation, input, zone: oldZone, area: oldArea } = playerData;

      const { rotation, position } = simulatePlayerMovement(collisionMesh, playerData);
      const { getZone } = useZoneStore.getState();
      const zone = position ? getZone(position, zoneGroup) : oldZone;
      // if (zone) console.log(zone);

      const { getArea } = useAreaStore.getState();
      const area = position ? getArea(position, areaGroup) : oldArea;
      // if (area) console.log(area);

      set({
        position: position || oldPosition,
        rotation: rotation || oldRotation,
        velocity: [...input],
        input: [0, 0, 0, 0],
        zone: zone,
        area,
      });
    },

    updatePosition: delta => {
      const { dirty, position, rotation, reaction, velocity } = get();
      //direct update for selfPlayer
      const selfPlayer = get().players[useUserStore.getState().player.id];
      if (selfPlayer) {
        selfPlayer.velocity = [...velocity];
        selfPlayer.position = [...position];
        selfPlayer.rotation = rotation;
        selfPlayer.reaction = reaction;
      }

      //interpolate other players
      const { players, playerIds } = get();
      playerIds.forEach(playerId => {
        const player = players[playerId];
        if (player.user.isSelf) return;

        if (player.velocity && player.targetPosition) {
          const instantUpdate = player.reaction === 10 || delta > 0.5;
          if (instantUpdate) {
            player.velocity[0] = 0;
            player.velocity[1] = 0;
            player.velocity[2] = 0;
            player.velocity[3] = 0;
            player.position[0] = player.targetPosition[0];
            player.position[1] = player.targetPosition[1];
            player.position[2] = player.targetPosition[2];
            player.rotation = player.targetRotation;
          } else {
            const res = interpolatePosRot(
              player.position,
              player.rotation,
              player.targetPosition,
              player.targetRotation,
              player.velocity,
              delta
            );
            player.position = res.position;
            player.rotation = res.rotation;
            player.velocity = res.velocity;
          }
        }
      });

      //apply to network
      if (Date.now() - lastSendMs > 50) {
        lastSendMs = Date.now();
        if (dirty) {
          set({ dirty: false });
          socket.emit('player/position', [...position, rotation]);
        }
      }

      set({});
    },
    move: (xAxis, yAxis, isJoystick, delta) => {
      if (get().controlLock) {
        return;
      }
      const { controlSchemes, controlSchemeId, rotation, input, reaction, playerActions } = get();
      const moveParams = { xAxis, yAxis, delta, rotation, isJoystick };
      const belowThreshold = Math.abs(xAxis) < 0.05 && Math.abs(yAxis) < 0.05;
      if (belowThreshold) {
        return;
      }
      const change = controlSchemes[controlSchemeId].move(moveParams);

      // add values into input reference TODO clean this up
      input[0] += change[0];
      input[1] += change[1];
      input[2] += change[2];
      input[3] += change[3];
      set({ dirty: true });
      if (reaction !== 0) {
        playerActions.setReaction(0);
      }
    },
    setReaction: reaction => {
      socket.emit('player/reaction', reaction);
      set({ reaction });
    },
    select: player => {
      set({ selectedPlayer: player });
    },
    goToPov: position => {
      get().playerActions.setReaction(10);
      set({ position: position });

      if (useEventStore.getState().event.useAvatars) {
        socket.emit('player/position', [...position, 0]);
        // slightly move to detect twilio-area
        setTimeout(() => {
          get().playerActions.move(0.1, 0.1, false, 0.1);
        }, 500);
      }
    },
  },

  logout: () => {
    set({ playerIds: [], players: {} });
  },
}));
