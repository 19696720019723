import { FullName } from '../styles';

import { useTranslate } from 'services/TranslationService/hooks';
import RoleBadge from 'common/components/Badge/RoleBadge';
import LanguageBadge from 'common/components/Badge/LanguageBadge';

export default function ProfileName({ user }) {
  const translate = useTranslate();
  const roleTypeToName = {
    [user.role.type]: user.role.name,
    authenticated: translate('profile.badge.attendee') || 'Attendee',
    expert: translate('profile.badge.expert') || 'Expert',
    admin: translate('profile.badge.admin') || 'Admin',
    supporter: translate('profile.badge.support') || 'Support',
  };
  return (
    <>
      <FullName key="name">
        {user.forename} {user.surname}
      </FullName>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <RoleBadge role={roleTypeToName[user.role.type]} />
        {user.language && <LanguageBadge languageId={user.language} />}
      </div>
    </>
  );
}
