import React, { useCallback, useEffect, useState } from 'react';
import ThreeCanvas from 'three/ThreeCanvas';
import { CustomizationUi } from './CustomizationUi';
import AvatarScene from './AvatarScene';
import { useAvatarStore } from 'services/AvatarService';
import { useUserStore } from 'services/UserService';
import { useHistory } from 'react-router-dom';
import VisualLoader from '../Play/VisualLoader';
import { useEventStore } from 'services/EventService';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';

function Done() {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return null;
}

function Error({ error }) {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return <ErrorFallbackUI message={error.message} />;
}

export default function CreateAvatar() {
  const defaultAppearance = useAvatarStore(state => state.getAppearance());
  const history = useHistory();
  const [showUI, setShowUI] = useState(true);
  const doneCallback = useCallback(() => {
    useUserStore.getState().changeAppearance(useAvatarStore.getState().getAppearance());
    useAvatarStore.getState().deselectCustomizationCategories();
    history.push('/');
  }, []);
  const useAvatars = useEventStore(state => state.event.useAvatars);

  useEffect(() => {
    const initialAppearance = useUserStore.getState().player.user.appearance;
    useAvatarStore.getState().setInitialConfiguration(initialAppearance);
    useDistrictWarmupStore.setState({ phase: WarmupPhase.LOADING });

    const t = setTimeout(() => {
      setShowUI(true);
    }, 200);
    return () => {
      clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    // skip avatar creation when in orbit mode (in the dumbest way possible)
    if (!useAvatars) doneCallback();
  }, [useAvatars, doneCallback]);

  return (
    <>
      <ThreeCanvas DoneComponent={Done} ErrorComponent={Error}>
        <AvatarScene appearance={defaultAppearance} />
      </ThreeCanvas>
      {showUI && <CustomizationUi initial={true} onDone={doneCallback} />}
      <VisualLoader />
    </>
  );
}
