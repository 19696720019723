import React, { useRef } from 'react';
import { useModalService } from 'services/ModalService';
import { useOnboardingStore } from 'services/OnboardingService';
import { Button } from 'common/components/Button';
import HBFLayout from 'common/components/HBFLayout';
import CloseButton from 'common/components/CloseButton';
import { InstructionParagraph, TitleContainer } from '../styles';
import { ContentContainer } from './styles';
import { useTranslate } from 'services/TranslationService/hooks';
import soundOnboarding from 'assets/soundOnboarding.png';
import { useSoundStore } from 'services/SoundService';

export default function SoundOnboarding() {
  const contentRef = useRef(null);

  const closeModal = () => {
    useModalService.getState().closeModal();
    useOnboardingStore.getState().finishOnboarding();
  };

  const translate = useTranslate();

  const header = (
    <TitleContainer>
      <CloseButton noBackground size={40} position={{ top: 0, right: 0 }} handler={closeModal} />
    </TitleContainer>
  );

  const body = (
    <ContentContainer ref={contentRef}>
      <InstructionParagraph>{translate('onboarding.sound.copy') || <>Enable your sound</>}</InstructionParagraph>
      <img src={soundOnboarding} />
    </ContentContainer>
  );

  const footer = (
    <>
      <Button
        accent
        fullWidth
        onClick={() => {
          useSoundStore.getState().setEnabled(true);
          closeModal();
        }}
      >
        {translate('onboarding.sound.confirm') || 'Enable Sound Now'}
      </Button>
    </>
  );

  return <HBFLayout header={header} body={body} footer={footer} />;
}
