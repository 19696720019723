import React, { useEffect } from 'react';
import HBFLayout from 'common/components/HBFLayout';
import { Button } from 'common/components/Button';
import { BodyContainer, Headline, Link } from './styles';
import { useOnboardingStore } from 'services/OnboardingService';
import { useChatStore } from 'services/ChatService';
import { useTrackingStore } from 'services/TrackingService';
import { useExpertStore } from 'services/ExpertService';
import { useTranslate } from 'services/TranslationService/hooks';
import { useEventStore } from 'services/EventService';

export default function HelpMenu({ closeMenu }) {
  const translate = useTranslate();
  const useAvatars = useEventStore.getState().event.useAvatars;

  useEffect(() => {
    useExpertStore.getState().requestExperts();
  }, []);

  const openSupportChat = () => {
    const expert = useExpertStore.getState().findAvailableExpert();
    if (expert) {
      closeMenu();
      useChatStore.getState().start([expert.id]);
      useTrackingStore.getState().trackEvent({ category: 'Support', action: 'Select', label: 'Chat' });
    }
  };

  const body = (
    <BodyContainer>
      <Headline>{translate('help.headline') || 'Need some help?'}</Headline>
      <p>
        {translate('help.text') ||
          'You have questions or need help? Our support team and experts are happy to assist you. For technical support,'}{' '}
        <Link onClick={openSupportChat}>{translate('help.cta') || 'contact us'}</Link>.
      </p>
    </BodyContainer>
  );
  const footer = useAvatars && (
    <Button
      accent
      noRadius
      fullWidth
      veStyle
      onClick={() => {
        closeMenu();
        useOnboardingStore.getState().repeatOnboarding();
      }}
    >
      {translate('help.onboarding') || 'Repeat Onboarding'}
    </Button>
  );
  return <HBFLayout body={body} footer={footer} />;
}
