import React, { useEffect, useRef, useState } from 'react';
import { BigCircle, CircleContainer, Container, InnerSmallCircle, SmallCircle } from './styles';
import { useWindowStore } from 'services/WindowService';
import { useChatStore } from 'services/ChatService';
import { useControlsStore } from 'services/ControlsService';
import { useUserStore } from 'services/UserService';

function JoystickControls() {
  const circleContainerRef = useRef();
  const bigCircleRef = useRef();
  const [dragging, setDragging] = useState(false);
  const [touching, setTouching] = useState(false);
  const joystickInput = useControlsStore(state => state.joystickInput);
  const setJoystickInput = useControlsStore.getState().setJoystickInput;
  const isChatOpen = useChatStore(state => state.isActive);
  const isShiftedByVideoChat = useChatStore(state => state.isShiftedByVideoChat);

  const requestRef = useRef();
  const joystickInputSubframe = useRef(null);
  const setJoystickInputSubframe = input => {
    joystickInputSubframe.current = input;
  };
  const updateJoystickInput = () => {
    if (joystickInputSubframe.current) {
      setJoystickInput(joystickInputSubframe.current);
      joystickInputSubframe.current = null;
    }
    requestRef.current = requestAnimationFrame(updateJoystickInput);
  };
  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateJoystickInput);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const [joystickSize, setJoystickSize] = useState(0);
  const width = useWindowStore(state => state.width);
  const height = useWindowStore(state => state.height);
  const hasFocus = useUserStore(state => state.hasFocus);

  useEffect(() => {
    setJoystickSize(circleContainerRef.current.offsetWidth);
  }, [width, height]);

  useEffect(() => {
    if (!hasFocus) endDragging();
  }, [hasFocus]);

  const startDragging = e => {
    setDragging(true);
    e.preventDefault();
    updatePosition(e);
  };

  const startTouchDragging = e => {
    setTouching(true);
    e.preventDefault();
    startDragging(e);
  };

  const endDragging = () => {
    setDragging(false);
    setJoystickInputSubframe({ x: 0, y: 0 });
  };

  const endTouchDragging = () => {
    setTouching(false);
    endDragging();
  };

  const updatePosition = e => {
    const joystickX = circleContainerRef.current.offsetLeft;
    const joystickY = circleContainerRef.current.offsetTop;
    const changedTouch =
      e.changedTouches && Object.values(e.changedTouches).find(x => x.target === bigCircleRef.current);
    const eventTarget = changedTouch || e;
    const relativeX = eventTarget.clientX - joystickX;
    const relativeY = eventTarget.clientY - joystickY;
    const calcPos = relPos => {
      const pos = -(2 * (relPos - joystickSize / 2)) / joystickSize;
      return pos;
    };
    const newPosition = {
      x: calcPos(relativeX),
      y: calcPos(relativeY),
    };
    const distance = Math.sqrt(newPosition.x * newPosition.x + newPosition.y * newPosition.y);
    newPosition.x /= distance;
    newPosition.y /= distance;
    newPosition.x *= Math.min(1, distance);
    newPosition.y *= Math.min(1, distance);
    setJoystickInputSubframe(newPosition);
  };

  const handleMove = e => {
    const isMouseDown = e.buttons === undefined ? e.which === 1 : e.buttons === 1;
    if (!isMouseDown && !touching) endDragging();
    if (dragging && (isMouseDown || touching)) {
      e.preventDefault();
      updatePosition(e);
    }
  };

  const smallCircleStyle = {
    transform: `translate(${-joystickInput.x * 100}%, ${-joystickInput.y * 100}%)`,
  };

  return (
    <Container
      onContextMenu={e => {
        e.nativeEvent.preventDefault();
      }}
      dragging={dragging}
      onTouchMove={handleMove}
      onMouseMove={handleMove}
      onMouseUp={endDragging}
      onTouchEnd={endTouchDragging}
      onTouchCancel={endTouchDragging}
    >
      <CircleContainer ref={circleContainerRef} isChatOpen={isChatOpen} isShiftedByVideoChat={isShiftedByVideoChat}>
        <BigCircle
          ref={bigCircleRef}
          dragging={dragging}
          onTouchStart={startTouchDragging}
          onMouseDown={startDragging}
        />
        <SmallCircle style={smallCircleStyle} dragging={dragging}>
          <InnerSmallCircle />
        </SmallCircle>
      </CircleContainer>
    </Container>
  );
}

export default JoystickControls;
