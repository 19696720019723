import IconShoes from './icons/category/icon_shoes.png';
import IconSkin from './icons/category/icon_skin.png';
import IconTop from './icons/category/icon_top.png';
import IconHair from './icons/category/icon_hair.png';
import IconGlasses from './icons/category/icon_glasses.png';
import IconBottom from './icons/category/icon_bottom.png';
import IconFacialDetails from './icons/category/icon_facial_details.png';

import Thumb_Hairstyle_00 from './icons/options/hair/Thumb_Hairstyle_00.png';
import Thumb_Hairstyle_01 from './icons/options/hair/Thumb_Hairstyle_01.png';
import Thumb_Hairstyle_02 from './icons/options/hair/Thumb_Hairstyle_02.png';
import Thumb_Hairstyle_03 from './icons/options/hair/Thumb_Hairstyle_03.png';
import Thumb_Hairstyle_04 from './icons/options/hair/Thumb_Hairstyle_04.png';
import Thumb_Hairstyle_05 from './icons/options/hair/Thumb_Hairstyle_05.png';
import Thumb_Hairstyle_06 from './icons/options/hair/Thumb_Hairstyle_06.png';
import Thumb_Hairstyle_07 from './icons/options/hair/Thumb_Hairstyle_07.png';
import Thumb_Hairstyle_08 from './icons/options/hair/Thumb_Hairstyle_08.png';
import Thumb_Hairstyle_09 from './icons/options/hair/Thumb_Hairstyle_09.png';
import Thumb_Hairstyle_10 from './icons/options/hair/Thumb_Hairstyle_10.png';
import Thumb_Hairstyle_11 from './icons/options/hair/Thumb_Hairstyle_11.png';
import Thumb_Hairstyle_12 from './icons/options/hair/Thumb_Hairstyle_12.png';
import Thumb_Hairstyle_13 from './icons/options/hair/Thumb_Hairstyle_13.png';
import Thumb_Hairstyle_14 from './icons/options/hair/Thumb_Hairstyle_14.png';
import Thumb_Hairstyle_15 from './icons/options/hair/Thumb_Hairstyle_15.png';
import Thumb_Hairstyle_16 from './icons/options/hair/Thumb_Hairstyle_16.png';
import Thumb_Hairstyle_17 from './icons/options/hair/Thumb_Hairstyle_17.png';
import Thumb_Hairstyle_18 from './icons/options/hair/Thumb_Hairstyle_18.png';
import Thumb_Hairstyle_19 from './icons/options/hair/Thumb_Hairstyle_19.png';

import Thumb_Glasses_00 from './icons/options/glasses/Thumb_Glasses_00.png';
import Thumb_Glasses_01 from './icons/options/glasses/Thumb_Glasses_01.png';
import Thumb_Glasses_02 from './icons/options/glasses/Thumb_Glasses_02.png';
import Thumb_Glasses_03 from './icons/options/glasses/Thumb_Glasses_03.png';
import Thumb_Glasses_04 from './icons/options/glasses/Thumb_Glasses_04.png';
import Thumb_Glasses_05 from './icons/options/glasses/Thumb_Glasses_05.png';
import Thumb_Glasses_06 from './icons/options/glasses/Thumb_Glasses_06.png';

import Thumb_Facial_Hair_00 from './icons/options/facialHair/Thumb_Facial_Hair_00.png';
import Thumb_Facial_Hair_01 from './icons/options/facialHair/Thumb_Facial_Hair_01.png';
import Thumb_Facial_Hair_02 from './icons/options/facialHair/Thumb_Facial_Hair_02.png';
import Thumb_Facial_Hair_03 from './icons/options/facialHair/Thumb_Facial_Hair_03.png';
import Thumb_Facial_Hair_04 from './icons/options/facialHair/Thumb_Facial_Hair_04.png';
import Thumb_Facial_Hair_05 from './icons/options/facialHair/Thumb_Facial_Hair_05.png';
import Thumb_Facial_Hair_06 from './icons/options/facialHair/Thumb_Facial_Hair_06.png';
import Thumb_Facial_Hair_07 from './icons/options/facialHair/Thumb_Facial_Hair_07.png';
import Thumb_Facial_Hair_08 from './icons/options/facialHair/Thumb_Facial_Hair_08.png';
import Thumb_Facial_Hair_09 from './icons/options/facialHair/Thumb_Facial_Hair_09.png';
import Thumb_Facial_Hair_10 from './icons/options/facialHair/Thumb_Facial_Hair_10.png';
import Thumb_Facial_Hair_11 from './icons/options/facialHair/Thumb_Facial_Hair_11.png';

import Thumb_Top_01 from './icons/options/top/Thumb_Top_01.png';
import Thumb_Top_02 from './icons/options/top/Thumb_Top_02.png';
import Thumb_Top_03 from './icons/options/top/Thumb_Top_03.png';
import Thumb_Top_04 from './icons/options/top/Thumb_Top_04.png';
import Thumb_Top_05 from './icons/options/top/Thumb_Top_05.png';
import Thumb_Top_06 from './icons/options/top/Thumb_Top_06.png';

import Thumb_Bottom_01 from './icons/options/bottom/Thumb_Bottom_01.png';
import Thumb_Bottom_02 from './icons/options/bottom/Thumb_Bottom_02.png';
import Thumb_Bottom_03 from './icons/options/bottom/Thumb_Bottom_03.png';
import Thumb_Bottom_04 from './icons/options/bottom/Thumb_Bottom_04.png';
import Thumb_Bottom_05 from './icons/options/bottom/Thumb_Bottom_05.png';
import Thumb_Bottom_06 from './icons/options/bottom/Thumb_Bottom_06.png';

import Thumb_Shoes_01 from './icons/options/shoes/Thumb_Shoes_01.png';
import Thumb_Shoes_02 from './icons/options/shoes/Thumb_Shoes_02.png';
import Thumb_Shoes_03 from './icons/options/shoes/Thumb_Shoes_03.png';
import Thumb_Shoes_04 from './icons/options/shoes/Thumb_Shoes_04.png';
import Thumb_Shoes_05 from './icons/options/shoes/Thumb_Shoes_05.png';
import Thumb_Shoes_06 from './icons/options/shoes/Thumb_Shoes_06.png';

import { character } from './colors';
import { useTranslationStore } from 'services/TranslationService';

export const getInitialCustomizationConfiguration = () => {
  const translate = useTranslationStore.getState().translate;
  return [
    {
      id: 'hair',
      name: translate('avatar.tab.hair') || 'Hairstyle',
      selected: false,
      customized: false,
      icon: IconHair,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Hairstyle_00, name: 'msm_hair_default' },
            { icon: Thumb_Hairstyle_01, name: 'msm_hair_18' },
            { icon: Thumb_Hairstyle_02, name: 'msm_hair_18b' },
            { icon: Thumb_Hairstyle_03, name: 'msm_hair_31' },
            { icon: Thumb_Hairstyle_04, name: 'msm_hair_32_A' },
            { icon: Thumb_Hairstyle_05, name: 'msm_hair_32_B' },
            { icon: Thumb_Hairstyle_06, name: 'msm_hair_33' },
            { icon: Thumb_Hairstyle_07, name: 'msm_hair_hat_A' },
            { icon: Thumb_Hairstyle_08, name: 'msm_hair_hat_B' },
            { icon: Thumb_Hairstyle_09, name: 'msm_hair_hat_C' },
            { icon: Thumb_Hairstyle_10, name: 'msm_hair_hat_D' },
            { icon: Thumb_Hairstyle_11, name: 'msm_hair_No3' },
            { icon: Thumb_Hairstyle_12, name: 'msm_hair_No6' },
            { icon: Thumb_Hairstyle_13, name: 'msm_hair_No7' },
            { icon: Thumb_Hairstyle_14, name: 'msm_hair_No9' },
            { icon: Thumb_Hairstyle_15, name: 'msm_hair_No17' },
            { icon: Thumb_Hairstyle_16, name: 'msm_hair_No17b' },
            { icon: Thumb_Hairstyle_17, name: 'msm_hair_No23' },
            { icon: Thumb_Hairstyle_18, name: 'msm_hair_No25' },
            { icon: Thumb_Hairstyle_19, name: 'msm_hair_No25b' },
            { icon: IconHair, name: 'HIDE_OPTION' },
            { icon: IconHair, name: 'HIDE_OPTION' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.hair['1'],
            character.hair['2'],
            character.hair['3'],
            character.hair['4'],
            character.hair['5'],
            character.hair['6'],
            character.hair['7'],
            character.hair['8'],
            character.hair['9'],
            character.hair['10'],
            character.hair['11'],
            character.hair['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'facial_features',
      name: translate('avatar.tab.glasses') || 'Glasses',
      selected: false,
      customized: false,
      icon: IconGlasses,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Glasses_00, name: 'msm_glasses_default' },
            { icon: Thumb_Glasses_01, name: 'msm_glasses_1' },
            { icon: Thumb_Glasses_02, name: 'msm_glasses_2' },
            { icon: Thumb_Glasses_03, name: 'msm_glasses_3' },
            { icon: Thumb_Glasses_04, name: 'msm_glasses_4' },
            { icon: Thumb_Glasses_05, name: 'msm_glasses_5' },
            { icon: Thumb_Glasses_06, name: 'msm_glasses_6' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'facial_details',
      name: translate('avatar.tab.facialhair') || 'Facial hair',
      selected: false,
      customized: false,
      icon: IconFacialDetails,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Facial_Hair_00, name: 'msm_facialHair_default' },
            { icon: Thumb_Facial_Hair_01, name: 'msm_facialHair_No1' },
            { icon: Thumb_Facial_Hair_02, name: 'msm_facialHair_No2' },
            { icon: Thumb_Facial_Hair_03, name: 'msm_facialHair_No3' },
            { icon: Thumb_Facial_Hair_04, name: 'msm_facialHair_No4' },
            { icon: Thumb_Facial_Hair_05, name: 'msm_facialHair_No5' },
            { icon: Thumb_Facial_Hair_06, name: 'msm_facialHair_No6' },
            { icon: Thumb_Facial_Hair_07, name: 'msm_facialHair_No7' },
            { icon: Thumb_Facial_Hair_08, name: 'msm_facialHair_No8' },
            { icon: Thumb_Facial_Hair_09, name: 'msm_facialHair_No9' },
            { icon: Thumb_Facial_Hair_10, name: 'msm_facialHair_No10' },
            { icon: Thumb_Facial_Hair_11, name: 'msm_facialHair_No11' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.hair['1'],
            character.hair['2'],
            character.hair['3'],
            character.hair['4'],
            character.hair['5'],
            character.hair['6'],
            character.hair['7'],
            character.hair['8'],
            character.hair['9'],
            character.hair['10'],
            character.hair['11'],
            character.hair['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'skin',
      name: translate('avatar.tab.skin') || 'Skin color',
      selected: false,
      customized: false,
      icon: IconSkin,
      optionPanels: [
        {
          type: 'color',
          options: [
            character.skin['1'],
            character.skin['2'],
            character.skin['3'],
            character.skin['4'],
            character.skin['5'],
            character.skin['6'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'top',
      name: translate('avatar.tab.top') || 'Top',
      selected: false,
      customized: false,
      icon: IconTop,
      camera: {
        position: null,
        target: { x: 0, y: 1.5, z: 0 },
      },
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Top_01, name: 'msm_top_No3' },
            { icon: Thumb_Top_02, name: 'msm_top_No7' },
            { icon: Thumb_Top_03, name: 'msm_top_No14' },
            { icon: Thumb_Top_04, name: 'msm_top_No20' },
            { icon: Thumb_Top_05, name: 'msm_top_No26' },
            { icon: Thumb_Top_06, name: 'msm_top_No31' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.top['1'],
            character.top['2'],
            character.top['3'],
            character.top['4'],
            character.top['5'],
            character.top['6'],
            character.top['7'],
            character.top['8'],
            character.top['9'],
            character.top['10'],
            character.top['11'],
            character.top['12'],
          ],
          selections: [
            { name: 'jacket', index: 0 },
            { name: 'shirt', index: 0 },
          ],
        },
      ],
    },

    {
      id: 'bottom',
      name: translate('avatar.tab.bottom') || 'Bottom',
      selected: false,
      customized: false,
      icon: IconBottom,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Bottom_01, name: 'msm_pants_No3' },
            { icon: Thumb_Bottom_02, name: 'msm_pants_No7' },
            { icon: Thumb_Bottom_03, name: 'msm_pants_No13' },
            { icon: Thumb_Bottom_04, name: 'msm_pants_No16' },
            { icon: Thumb_Bottom_05, name: 'msm_pants_No21' },
            { icon: Thumb_Bottom_06, name: 'msm_pants_No22' },
            // { icon: IconBottom, name: 'msm_pants_No25' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.top['1'],
            character.top['2'],
            character.top['3'],
            character.top['4'],
            character.top['5'],
            character.top['6'],
            character.top['7'],
            character.top['8'],
            character.top['9'],
            character.top['10'],
            character.top['11'],
            character.top['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'shoes',
      name: translate('avatar.tab.shoes') || 'Shoes',
      selected: false,
      customized: false,
      icon: IconShoes,
      camera: {
        position: { x: 2, y: 1, z: 0 },
        target: { x: 0.1, y: 0.1, z: 0 },
      },
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Shoes_01, name: 'msm_shoes_No3' },
            { icon: Thumb_Shoes_02, name: 'msm_shoes_No7' },
            { icon: Thumb_Shoes_03, name: 'msm_shoes_No16' },
            { icon: Thumb_Shoes_04, name: 'msm_shoes_No17' },
            { icon: Thumb_Shoes_05, name: 'msm_shoes_No21' },
            { icon: Thumb_Shoes_06, name: 'msm_shoes_No29' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
        {
          type: 'color',
          options: [
            character.top['1'],
            character.top['2'],
            character.top['3'],
            character.top['4'],
            character.top['5'],
            character.top['6'],
            character.top['7'],
            character.top['8'],
            character.top['9'],
            character.top['10'],
            character.top['11'],
            character.top['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },
  ];
};
