import { useFrame, useLoader } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import { CONTENT_TYPE } from 'services/DistrictService/contentTypes';
import { useLightmaps } from 'services/MaterialService/hooks';
import { useWindowStore } from 'services/WindowService';
import {
  AdditiveBlending,
  AnimationMixer,
  Box3,
  BoxGeometry,
  LinearFilter,
  LinearMipMapLinearFilter,
  MeshBasicMaterial,
  sRGBEncoding,
  Vector3,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useEventStore } from 'services/EventService';
import fromCdn from 'utilities/cdn';
import { useDebugStore } from 'storage/debug';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';

const configureLightMap = lightMap => {
  lightMap.flipY = false;
  lightMap.generateMipmaps = true;
  lightMap.anisotropy = 16;
  lightMap.minFilter = LinearMipMapLinearFilter;
  lightMap.magFilter = LinearFilter;
  lightMap.encoding = sRGBEncoding;
};

const Product = ({ product }) => {
  const setWindowStoreHover = useWindowStore(state => state.setHover);
  const fbxOrGltf = useLoader(product.mesh.endsWith('fbx') ? FBXLoader : GLTFLoader, fromCdn(product.mesh));
  const lightmaps = useLightmaps();

  const [sceneData, setSceneData] = useState(null);

  //mount
  useEffect(() => {
    const objectScene = product.mesh.endsWith('fbx') ? fbxOrGltf : clone(fbxOrGltf.scene);
    objectScene.traverse(o => {
      if (o.material) {
        const lightmap = lightmaps.find(lm => o.name.includes(lm.tag));
        if (lightmap) {
          configureLightMap(lightmap.texture);
          o.material.lightMap = lightmap.texture;
        }
        o.castShadow = true;
        o.receiveShadow = true;

        if (o.material.name === 'cloud') {
          o.castShadow = false;
          o.receiveShadow = false;
        }

        if (o.material.name.includes('_add')) {
          o.material.blending = AdditiveBlending;
          o.material.transparent = true;
          o.receiveShadow = false;
          o.castShadow = false;
        }
      }
    });

    const bb = new Box3().setFromObject(objectScene);
    const boxGeom = new BoxGeometry(bb.max.x - bb.min.x, bb.max.y - bb.min.y, bb.max.z - bb.min.z);
    const boxMat = new MeshBasicMaterial({ color: 0xffffff, visible: false });
    const boxPos = new Vector3(0, bb.max.y * 0.5, 0);

    const { animations } = fbxOrGltf;
    const animationMixer = new AnimationMixer();
    if (animations.length > 0) {
      const { animations } = fbxOrGltf;
      animations.forEach(animation => {
        const action = animationMixer.clipAction(animation, objectScene);
        action.setLoop(true);
        action.play();
      });
    }

    const content = {
      type: CONTENT_TYPE.PRODUCT,
      headline: product.headline || 'Product',
      pin: {
        normal: [0.6, 0, -0.76],
        offset: [0, 0, 0],
        scale: 0.3,
      },
      product: {
        ...product,
        object: clone(objectScene),
      },
    };

    setSceneData({
      animationMixer,
      objectScene,
      boxGeom,
      boxMat,
      boxPos,
      content,
    });
  }, [fbxOrGltf]);

  // update animation mixer
  useFrame((c, delta) => {
    const animationMixer = sceneData?.animationMixer;
    if (animationMixer) {
      animationMixer.update(delta);
    }
  });

  const splitByComma = string => {
    return string ? string.split(',').map(x => Number(x)) : [0, 0, 0];
  };

  const autoRotation = splitByComma(product.autoRotation);
  const productRef = useRef(null);

  useFrame((context, deltaTime) => {
    if (!productRef.current || autoRotation == null) return;
    const K = (deltaTime / 180) * Math.PI;
    productRef.current.rotation.x += autoRotation[0] * K;
    productRef.current.rotation.y += autoRotation[1] * K;
    productRef.current.rotation.z += autoRotation[2] * K;
  });

  if (!sceneData) return null;

  const { objectScene, boxPos, boxGeom, boxMat, content } = sceneData;

  const enableClickMesh = content.product.productContents.length > 0;

  return (
    <group
      name={product.headline}
      position={splitByComma(product.location)}
      rotation={splitByComma(product.rotation)}
      scale={splitByComma(product.scale)}
      ref={productRef}
    >
      <primitive object={objectScene} />
      {enableClickMesh && (
        <mesh
          position={boxPos}
          geometry={boxGeom}
          material={boxMat}
          onPointerOver={e => {
            e.stopPropagation();
            setWindowStoreHover(true);
          }}
          onPointerOut={() => {
            setWindowStoreHover(false);
          }}
          onClick={e => {
            e.stopPropagation();
            useContentStore.getState().setActiveContent(content);
          }}
        />
      )}
    </group>
  );
};

export default function Products({ district, visible }) {
  const products = useEventStore(state => state.event.products.filter(p => p.district === district.room));
  const debugStore = useDebugStore();

  if (debugStore.getMinimal3d()) return null;
  if (!products) return null;
  return (
    <group name={'Products'} visible={visible}>
      {products.map((product, i) => {
        return <Product key={i} product={product} />;
      })}
    </group>
  );
}
