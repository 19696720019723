import styled, { css, keyframes } from 'styled-components';
import { colors, zIndex } from 'uiLivi/styles';

const rotate = keyframes`
  from { transform: rotate(45deg); }
  to { transform: rotate(405deg); }
`;

export const Spinner = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-weight: bold;
  color: ${colors.font.light};
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loader};
  background-color: ${colors.background.base};
  padding: 50px;
  transition: opacity ${props => props.fadeDuration}ms;
  opacity: ${props => (props.isLoaded ? 0.0 : 1)};
  transition-delay: ${props => (props.isLoaded ? props.fadeDelay : 0)}ms;
  pointer-events: none;
  display: ${props => (props.hide ? 'none' : 'block')};
  ${Spinner} {
    transition: opacity;
    opacity: ${props => (props.isLoaded ? 0.0 : 1)};
    transition-duration: ${props => (props.isLoaded ? props.fadeDuration : 0)}ms;
    ${props =>
      props.hide
        ? css`
            animation: none;
          `
        : null};
  }
`;

export const Ring = styled.div`
  position: absolute;
  transform: rotate(45deg);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  animation-name: ${rotate};
  animation-duration: 2.6s;
  animation-iteration-count: infinite;

  &.black-one,
  &.black-two {
    border-color: ${colors.accent.base} ${colors.accent.highlight} transparent transparent;
    animation-timing-function: cubic-bezier(0.51, 0.06, 0.48, 0.93);
  }
  &.black-two {
    animation-delay: 0.4s;
  }
`;
