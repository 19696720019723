import create from 'utilities/zustand/create';
import userStorage from 'storage/user';
import { usePostStore } from 'three/Renderer/store';
import { getGPUTier } from 'detect-gpu';

export const useQualitySettings = create((set, get) => {
  function makeSetting(modifier) {
    const { renderConfiguration } = usePostStore.getState();
    const copy = JSON.parse(JSON.stringify(renderConfiguration));
    if (modifier) modifier(copy);
    return { renderConfiguration: copy };
  }

  return {
    setting: -1,
    settings: [
      {
        label: 'Eco',
        ...makeSetting(renderConfiguration => {
          renderConfiguration.pixelRatio = 0.8;
          renderConfiguration.postEnabled = false;
          renderConfiguration.shadow.enabled = false;
        }),
      },
      {
        label: 'Normal',
        ...makeSetting(renderConfiguration => {
          renderConfiguration.pixelRatio = 1.25;
          renderConfiguration.postEnabled = false;
          renderConfiguration.shadow.enabled = false;
        }),
      },
      {
        label: 'High',
        ...makeSetting(renderConfiguration => {
          renderConfiguration.pixelRatio = 1.0;
          renderConfiguration.postEnabled = true;
          renderConfiguration.postMultisampling = 0;
          renderConfiguration.bloom.enabled = true;
          renderConfiguration.shadow.enabled = true;
          renderConfiguration.shadow.size = 2048;
        }),
      },
      {
        label: 'Ultra',
        ...makeSetting(renderConfiguration => {
          renderConfiguration.pixelRatio = 1.5;
          renderConfiguration.postEnabled = true;
          renderConfiguration.postMultisampling = 4;
          renderConfiguration.bloom.enabled = true;
          renderConfiguration.shadow.enabled = true;
          renderConfiguration.shadow.size = 4096;
        }),
      },
    ],
    options: [],
    init: async () => {
      const gpu = await getGPUTier();
      const defaultOptionIndex = gpu.tier <= 2 ? 0 : gpu.isMobile ? 1 : 2;
      userStorage.initQualitySetting(defaultOptionIndex);

      const { settings } = get();
      const options = settings.reduce((acc, s) => {
        acc.push({ ...s, selected: false, default: false });
        return acc;
      }, []);

      const selectedOption = options[userStorage.getQuality()];
      selectedOption.selected = true;

      const defaultOption = options[defaultOptionIndex];
      defaultOption.default = true;

      usePostStore.getState().set(selectedOption.renderConfiguration);
      set({ options });
    },

    changeQualityOption: index => {
      userStorage.setQuality(index);
      const options = get().options.map((o, i) => {
        return { ...o, selected: i === index };
      });
      const selectedOption = options.find(o => o.selected);
      usePostStore.getState().set(selectedOption.renderConfiguration);
      set({ options });
    },
  };
});
