import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import { LinkContainer, LogoWrapper, StyledLink } from './styles';

export default function PoweredByLink() {
  const [isHovered, setIsHovered] = useState(true);
  useEffect(() => {
    setIsHovered(false);
  }, []);

  return (
    <LinkContainer hovered={isHovered} onMouseLeave={() => setIsHovered(false)}>
      <LogoWrapper onMouseEnter={() => setIsHovered(true)}>
        <Logo filled={isHovered} />
      </LogoWrapper>
      <StyledLink href="https://demodern.com" target={'_blank'} expand={isHovered}>
        powered by<b>demodern</b>
      </StyledLink>
    </LinkContainer>
  );
}
