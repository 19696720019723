import React, { useRef } from 'react';
import Hls from 'hls.js';
import { Container, Video } from './styles';
import { useTheaterContent } from 'utilities/hooks';

export const createVideo = (video, videoSrc) => {
  video.setAttribute('playsinline', '');
  video.setAttribute('autoplay', '');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.loop = true;
  video.playsinline = true;
  video.muted = false;
  video.controls = true;
  video.crossOrigin = 'anonymous';
  video.width = 1920;

  if (!videoSrc) return video;

  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }
  } else {
    // const source = document.createElement('source');
    // source.src = videoSrc;
    // video.append(source);
    video.src = videoSrc;
  }
  return video;
};

export default function LiveStream(props) {
  const { content: defaultContent } = props;
  const { content } = useTheaterContent(defaultContent);
  const ref = useRef();

  ref.current && createVideo(ref.current, content);

  return (
    <Container>
      <Video ref={ref} poster={content} />
    </Container>
  );
}
