import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, disabledStyle } from 'common/components/Button';
import HBFLayout from 'common/components/HBFLayout';
import { marginTopAndButton } from '../../styles';
import { closeButtonSize, menuPaddingPx } from '../styles';
import { useTranslate } from 'services/TranslationService/hooks';
import { useDistrictStore } from 'services/DistrictService';
import { usePovStore } from 'services/PovService';
import IconPin from 'assets/icons/Icon_Districts.svg';

const StyledLocationMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${menuPaddingPx};
`;

const PinIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const LocationButton = styled(Button)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  ${props => (props.selected ? disabledStyle : null)}
`;

function LocationMenu({ closeMenu }) {
  const history = useHistory();
  const { mapId } = useParams();
  const translate = useTranslate();
  const districtList = useDistrictStore(state => state.getSimpleDistrictListWithPOV());
  const { setPov } = usePovStore.getState();

  const handleDistrictChange = roomId => {
    history.push(`/play/${roomId}`);
    closeMenu();
  };

  const handleClick = item => {
    if (item.type !== 'pov') {
      setPov(null);
      handleDistrictChange(item.id);
    } else {
      setPov(item);
      closeMenu();
    }
  };

  const body = (
    <StyledLocationMenu>
      {districtList.map((item, index) => {
        if (item.type === 'pov' && mapId !== item.room) return null;
        return (
          <LocationButton
            veStyle
            key={index}
            selected={mapId === item.id}
            small
            accent
            onClick={() => handleClick(item)}
          >
            {item.type === 'pov' && (
              <PinIconWrapper>
                <IconPin width={20} height={20} />
              </PinIconWrapper>
            )}
            {translate(`room.${item.id}`) || item.name}
          </LocationButton>
        );
      })}
    </StyledLocationMenu>
  );

  return <HBFLayout body={body} fullHeightMinus={marginTopAndButton * 2 + closeButtonSize} />;
}

export default LocationMenu;
