import { Box, IconContainer } from './styles';
import { useAvatarStore } from 'services/AvatarService';
import Indicator from '../../Indicator';

export default function Option({
  option,
  optionIndex,
  appearIndex,
  selected,
  categoryId,
  panelIndex,
  panelType,
  selectedTopPageIndex,
}) {
  const selectCustomizationOption = useAvatarStore(state => state.selectCustomizationOption);

  const color = panelType === 'color' ? option : 'black';
  const icon = panelType === 'style' ? option.icon : null;
  const show = option.name !== 'HIDE_OPTION';

  const handleOptionClick = () => {
    if (categoryId === 'top') {
      selectCustomizationOption(categoryId, panelIndex, optionIndex, selectedTopPageIndex);
    } else {
      selectCustomizationOption(categoryId, panelIndex, optionIndex);
    }
  };

  const showCheckIcon =
    categoryId === 'top' && panelIndex > 0
      ? selectedTopPageIndex === 0
        ? selected.includes('jacket')
        : selected.includes('shirt')
      : selected.includes('default');

  return (
    <Box color={color} icon={icon} appearIndex={appearIndex} onClick={handleOptionClick} show={show}>
      <IconContainer icon={icon} />
      <Indicator showCheckIcon={showCheckIcon} />
      {/*<div*/}
      {/*  style={{*/}
      {/*    position: 'absolute',*/}
      {/*    left: '-3px',*/}
      {/*    top: '-3px',*/}
      {/*    fontWeight: 'bold',*/}
      {/*    fontSize: '11px',*/}
      {/*    color: '#f87822',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {optionIndex}*/}
      {/*</div>*/}
    </Box>
  );
}
