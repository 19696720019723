import React, { useEffect, useRef } from 'react';
import { useModalService } from 'services/ModalService';
import { useMenuStore } from 'services/MenuService';
import { Button } from 'common/components/Button';
import HBFLayout from 'common/components/HBFLayout';
import CloseButton from 'common/components/CloseButton';
import { ContentContainer, InstructionParagraph, Title, TitleContainer } from '../styles';
import { MenuItemContainer } from './styles';
import MenuItem from 'uiLivi/components/Menu/MenuItem';
import IconProfile from 'assets/icons/Icon_Profile.svg';
import { useTranslate } from 'services/TranslationService/hooks';
import { useOnboardingStore } from 'services/OnboardingService';
import { useDeviceStore } from 'services/DeviceService';

export default function ProfileOnboarding() {
  const contentRef = useRef(null);

  const closeModal = () => {
    useModalService.getState().closeModal();
    const { isMobile } = useDeviceStore.getState().device;
    isMobile && useOnboardingStore.getState().finishOnboarding();
  };

  useEffect(() => {
    useMenuStore.getState().highlightMenuItem('profile');
    return () => {
      useMenuStore.getState().removeMenuItemHighlights();
    };
  }, []);

  const translate = useTranslate();

  const header = (
    <TitleContainer>
      <Title>{translate('onboarding.profile.title') || 'Get started'}</Title>
      <CloseButton noBackground size={40} position={{ top: 0, right: 0 }} handler={closeModal} />
    </TitleContainer>
  );

  const body = (
    <ContentContainer ref={contentRef}>
      <InstructionParagraph>
        {translate('onboarding.profile.copy') || (
          <>
            Share a little more about yourself in the profile section. Adding your job title and country help you
            connect better with other participants.
          </>
        )}
      </InstructionParagraph>
      <MenuItemContainer>
        <MenuItem
          mI={{
            categoryTitle: translate('profile.edit.button') || 'Profile',
            id: 'profile',
            icon: IconProfile,
          }}
          clickHandler={() => {
            useMenuStore.getState().setMenuItemActive('profile', true);
            closeModal();
          }}
        />
      </MenuItemContainer>
    </ContentContainer>
  );

  const footer = (
    <>
      <Button accent fullWidth onClick={closeModal}>
        {translate('onboarding.profile.confirm') || 'Got it!'}
      </Button>
    </>
  );

  return <HBFLayout header={header} body={body} footer={footer} />;
}
