import Url from 'url-parse';

export const cdnConfig = {
  host: '',
  protocol: '',
};

export function configureCdn(url) {
  const parsed = new Url(url);
  cdnConfig.host = parsed.host;
  cdnConfig.protocol = parsed.protocol;
}

export default function fromCdn(urlOrArray) {
  if (!cdnConfig.host || !cdnConfig.protocol) {
    throw new Error('CDN not configured!');
  }

  function replace(url) {
    const cdnUrl = new Url(url);
    cdnUrl.set('host', cdnConfig.host, null);
    cdnUrl.set('protocol', cdnConfig.protocol, null);
    return cdnUrl.href;
  }

  if (urlOrArray instanceof Array) {
    return urlOrArray.map(url => {
      return replace(url);
    });
  } else {
    return replace(urlOrArray);
  }
}
