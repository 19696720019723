export const DEFAULT_APPEARANCE = {
  accesoires: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  bottom: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  facial_details: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  facial_features: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  hair: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  shoes: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  skin: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
  top: {
    color: {
      default: 0,
    },
    style: {
      default: 0,
    },
  },
};
