import { useLoader } from '@react-three/fiber';
import { useDistrictStore } from 'services/DistrictService';
import { TextureLoader } from 'three';
import { useSceneStore } from '../../SceneService';
import { useEffect } from 'react';
import fromCdn from 'utilities/cdn';

export const useLightmaps = () => {
  const lmsData = useDistrictStore(state => state.district && state.district.lightmaps);
  const lmsTexs = useLoader(TextureLoader, fromCdn(lmsData ? lmsData.map(lm => lm.asset) : []));

  const lightmaps =
    lmsData &&
    lmsData.map(lightmap => ({
      ...lightmap,
      texture: lmsTexs.find(lm => lm.image.src === fromCdn(lightmap.asset)),
    }));

  return lightmaps;
};

export const useTraverse = (traverse, deps = []) => {
  const renderable = useSceneStore(state => state.scene && state.scene.renderable);

  useEffect(() => {
    if (!renderable) return;
    const fn = traverse();
    if (!fn) return;
    renderable.traverse(o => {
      try {
        if (o.orgMaterial) {
          fn(o);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('traverse', e);
      }
    });
  }, [renderable].concat(deps));
};
