import React, { useEffect, useRef, useState } from 'react';
import { ParticipantInitials, ParticipantView, VoiceIndicator } from '../styles';

// threshold above which the indicator is animated (normalized range, 0-1)
const cutoff = 0.0125;
// exponent to scale total normalized level for animation
const exponent = 20;

export default function Participant({ participant, initials }) {
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const videoElRef = useRef(null);
  const audioElRef = useRef(null);
  const analyzerNode = useRef(null);
  const freauencyDataBuffer = useRef(null);
  const indicatorRef = useRef(null);

  useEffect(() => {
    if (!participant) return;
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTrack(track);
      } else if (track.kind === 'audio') {
        setAudioTrack(track);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTrack(null);
      } else if (track.kind === 'audio') {
        setAudioTrack(null);
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    if (participant.audioTracks.size > 0) {
      setAudioTrack(participant.audioTracks.values().next().value.track);
    }

    return () => {
      setVideoTrack(null);
      setAudioTrack(null);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    if (audioTrack && audioElRef.current) {
      audioTrack.attach(audioElRef.current);
      audioElRef.current.play();

      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTrack, audioElRef.current]);

  useEffect(() => {
    if (videoTrack && videoElRef.current) {
      videoTrack.attach(videoElRef.current);
      videoElRef.current.play();

      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTrack, videoElRef.current]);

  useEffect(() => {
    if (audioTrack) {
      const mediaStream = new MediaStream([audioTrack.mediaStreamTrack]);
      const context = new AudioContext();

      const analyzer = context.createAnalyser();
      analyzer.fftSize = 128;

      const source = context.createMediaStreamSource(mediaStream);
      freauencyDataBuffer.current = new Uint8Array(analyzer.frequencyBinCount);

      // filter out frequencies outside the vocal range
      const filter = context.createBiquadFilter();
      filter.type = 'bandpass';
      filter.frequency.value = 175;
      filter.Q.value = 1.75;

      source.connect(filter);
      filter.connect(analyzer);
      // output is handled by oudio track
      // analyzer.connect(context.destination);

      analyzerNode.current = analyzer;

      return () => {
        // analyzer.disconnect(context.destination);
        source.disconnect(filter);
        filter.connect(analyzer);
        analyzerNode.current = null;
        freauencyDataBuffer.current = null;
      };
    }
  }, [audioTrack]);

  // useFrame throws for some reason.
  useEffect(() => {
    let raf;
    const loop = () => {
      if (!videoTrack && indicatorRef.current && analyzerNode.current && freauencyDataBuffer.current) {
        analyzerNode.current.getByteFrequencyData(freauencyDataBuffer.current);

        const total = freauencyDataBuffer.current.reduce((a, b) => a + b) / freauencyDataBuffer.current.length / 255;
        const scalar = Math.max(0, -Math.exp(-exponent * total + cutoff * exponent) + 1);

        indicatorRef.current.style.transform = `scale(${0.5 + 0.5 * scalar})`;
      }
      raf = requestAnimationFrame(loop);
    };
    raf = requestAnimationFrame(loop);

    return () => cancelAnimationFrame(raf);
  });

  return (
    <ParticipantView>
      {videoTrack && <video ref={videoElRef}></video>}
      {audioTrack && <audio ref={audioElRef}></audio>}
      <ParticipantInitials>{initials}</ParticipantInitials>
      <VoiceIndicator ref={indicatorRef} />
    </ParticipantView>
  );
}
