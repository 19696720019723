import styled, { css } from 'styled-components';
import { colors, lgBaseColors, veGradientLtR } from 'uiLivi/styles';
import { H1, H2, H5, smallTextStyle } from 'common/text';
import { mediaMax, mediaMin, styledScrollbar } from 'common/layout';
import { Button } from 'common/components/Button';
import { StyledInput } from 'common/components/Input';

const linkStyle = css`
  ${smallTextStyle};
  color: ${colors.accent.base};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;

export const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: ${props => props.vh * 100}px;
  background-color: #000;
  color: ${colors.font.light};
  ${mediaMax(
    'small',
    css`
      overflow-x: hidden;
      overflow-y: auto;
      ${styledScrollbar};
    `
  )}
`;

export const FormContainer = styled.div`
  float: left;
  width: 50%;
  background-color: ${colors.background.base};
  ${mediaMax(
    'small',
    css`
      width: 100%;
    `
  )}

  ${mediaMin(
    'medium',
    css`
      padding: 40px;
      height: 100%;
      overflow-y: auto;
      ${styledScrollbar};
    `
  )}
`;

export const NameFieldWrapper = styled.div`
  display: flex;

  ${mediaMax(
    'small',
    css`
      display: block;
    `
  )}

  ${mediaMin(
    'medium',
    css`
      ${StyledInput} {
        max-width: calc(50% - 7.5px);
      }
    `
  )}
`;

export const IntroContainer = styled.div`
  position: relative;
  float: left;
  width: 50%;
  padding: 100px;
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaMax(
    'medium',
    css`
      padding: 40px;
    `
  )};

  ${mediaMax(
    'small',
    css`
      width: 100%;
      padding: 10vh 20px;
    `
  )};

  ${mediaMin(
    'medium',
    css`
      height: 100%;
      overflow-y: auto;
      ${styledScrollbar};
    `
  )};
`;

export const Content = styled.div`
  display: table;
  position: relative;
  z-index: 1;
`;

export const BrandingLogo = styled.img`
  height: 34px;
  width: 200px;
  display: block;
  object-fit: contain;
  object-position: 0 0;
`;

export const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: table-row;
  width: 100%;
`;

export const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const IntroHeadline = styled(H1)`
  text-align: left;
  color: ${props => (props.accent ? colors.accent.base : lgBaseColors.white)};
  margin: 0;
  ${mediaMax(
    'small',
    css`
      font-size: 32px;
      line-height: 36px;
    `
  )};

  ${props =>
    props.large &&
    css`
      font-family: demodernsuper;
      font-size: 40px;
      line-height: 48px;
    `};

  ${props =>
    props.regular &&
    css`
      font-family: demodernthin;
      font-size: 60px;
      line-height: 72px;
      margin: 5vh 0 5vh 0;
      max-width: 480px;
    `};
`;

export const IntroText = styled.div`
  color: ${lgBaseColors.white};
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
`;

export const IntroSubline = styled(H5)`
  font-weight: 400;
  font-size: 16px;
  margin: 2vh 0;
  color: #4e4e4e;
`;

export const Form = styled.form`
  margin: auto;
  width: 100%;
  ${mediaMin(
    'medium',
    css`
      max-width: 377px;
    `
  )};
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 180px;
`;

export const DataShizzle = styled.div`
  display: flex;
  align-items: center;
  ${smallTextStyle};
  margin: 2vh 0;
  span {
    color: ${colors.font.light};
    cursor: pointer;
  }
  a,
  a:link,
  a:visited {
    ${linkStyle};
  }
`;

export const ForgotPassword = styled.div`
  ${linkStyle};
  font-size: 11px;
  margin: 3vh 0;
  background: ${veGradientLtR};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  span {
    font-size: 13px;
  }
`;

export const PanelHeadline = styled(H2)`
  text-transform: capitalize;
  color: ${colors.font.strong};
  font-weight: bold;
  margin: 0 0 20px;
  letter-spacing: 1.1px;

  ${mediaMax(
    'small',
    css`
      margin: 0;
    `
  )}
`;

export const PanelCopy = styled.div`
  display: flex;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

export const ErrorMessage = styled.div`
  ${smallTextStyle};
  font-weight: bold;
  color: ${colors.error};
  letter-spacing: 1.1px;
  display: flex;
`;

export const InputLabel = styled.div`
  margin-top: 20px;
  color: ${lgBaseColors.grey.light};
`;
