import { usePlayerStore } from 'services/PlayerService';
import { useUserStore } from 'services/UserService';
import create from 'utilities/zustand/create';

let socket = null;

export const globalPlayers = {};

export const useGlobalHubStore = create((set, get) => ({
  userIds: [],
  ivId: 0,
  init: managedSocket => {
    socket = managedSocket;

    socket.on('globalHub/receive', data => {
      const t = globalPlayers[data.userId]?.t || Date.now();
      const netDt = Math.min(Date.now() - t, 1000);
      globalPlayers[data.userId] = { ...data, netDt };
      set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
    });
    socket.on('globalHub/disconnect', id => {
      delete globalPlayers[id];
      set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
    });

    const ivId = setInterval(() => {
      if (!useUserStore.getState().user) return;

      const tenSecondsAgo = Date.now() - 10000;
      Object.values(globalPlayers)
        .filter(p => p.t < tenSecondsAgo)
        .forEach(p => {
          delete globalPlayers[p.userId];
          set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
        });
      const userId = useUserStore.getState().user.id;
      const isExpert = useUserStore.getState().user.role.type === 'expert';
      if (isExpert && !globalPlayers[userId]) {
        // eslint-disable-next-line no-console
        console.warn('Not receiving from global hub');
      }
      get().transmitPlayerState();
    }, 1000);
    set({ ivId });
  },
  exit: () => {
    clearInterval(get().ivId);
  },

  transmitPlayerState: () => {
    const { user } = useUserStore.getState();
    const { id: userId, appearance, role } = user;
    const isGlobalPlayer = role.type === 'expert';
    if (!isGlobalPlayer) {
      return;
    }
    const { position, rotation, velocity } = usePlayerStore.getState();
    const data = {
      userId,
      appearance,
      position,
      rotation,
      velocity,
      t: Date.now(),
    };
    socket.emit('globalHub/transmit', data);
  },
}));
