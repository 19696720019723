import React, { useEffect, useRef } from 'react';
import { createImageMaterial, createVideoMaterial } from 'utilities/content';
import { sharedTextures } from 'services/MaterialService/AuditoriumMaterials';
import fromCdn from 'utilities/cdn';
import { MeshStandardMaterial } from 'three';
import { useDebugStore } from 'storage/debug';

export default function Teaser({ object, content, createSharedTexture = false }) {
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());
  const meshRef = useRef();
  if (!object) {
    return null;
  }

  useEffect(() => {
    if (debugMinimal3d) {
      meshRef.current.material = new MeshStandardMaterial({ color: 0x99ff99 });
      meshRef.current.material.envMapIntensity = 0;
      return;
    }

    const materialFactory = {
      video: () => {
        return createVideoMaterial(fromCdn(content.teaser), object.position).material;
      },
      image: () => {
        return createImageMaterial(fromCdn(content.teaser), object.position).material;
      },
    };

    const material = materialFactory[content.teaserType]();

    if (createSharedTexture) sharedTextures.videoTexture = material.map;
    meshRef.current.material = material;
    return () => {
      material.dispose();
    };
  }, [debugMinimal3d, content.teaser, content.teaserType]);

  return <mesh name={'Teaser'} geometry={object.geometry} ref={meshRef} position={[0, 0, 0]} />;
}
