import create from 'utilities/zustand/create';

const presets = {
  lobby: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: -0.929,
      inclination: -1.039,
      mixValue: 0.248,
    },
    water: {
      enabled: true,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 0.85,
    },
    envMap: {
      intensity: 0.7,
      intensityLightMaps: 0.15,
    },
  },
  auditorium: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#92BFF1',
      intensity: 0.8,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: -1.559,
      inclination: 1.573,
      mixValue: 0.5,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.08,
      intensityLightMaps: 0.15,
    },
  },
  fittingRoom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ff9a44',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: 0.787,
      inclination: 0.4,
      mixValue: 0.248,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.7,
      intensityLightMaps: 0.15,
    },
  },
  showroom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: 1.94,
      inclination: 0.814,
      mixValue: 0.0,
    },
    water: {
      enabled: true,
      seaLevel: -0.6,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.7,
      intensityLightMaps: 0.5,
    },
  },
};

export const useEnvironmentStore = create(set => ({
  presets: presets,
  environmentConfiguration: JSON.parse(JSON.stringify(presets.lobby)),

  set: environmentConfiguration => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(environmentConfiguration, null, 2));
    set({ environmentConfiguration: JSON.parse(JSON.stringify(environmentConfiguration)) });
  },

  setPresetByName: name => {
    set(state => {
      if (state.presets[name]) {
        return { environmentConfiguration: JSON.parse(JSON.stringify(state.presets[name])) };
      } else {
        return state;
      }
    });
  },
}));
