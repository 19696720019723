import React, { useEffect, useState } from 'react';
import { Loader, Ring, Spinner } from './styles';
import { useDistrictStore } from 'services/DistrictService';
import { useEventStore } from 'services/EventService';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';

const fadeDuration = 500;
const fadeDelay = 10;

export default function VisualLoader() {
  const phase = useDistrictWarmupStore(state => state.phase);
  const loadingProgress = useDistrictWarmupStore(state => state.loadingProgress);
  const enteringProgress = useDistrictWarmupStore(state => state.enteringProgress);
  const isShown = phase === WarmupPhase.DONE;

  const district = useDistrictStore(state => state.district);
  const event = useEventStore(state => state.event);

  const [hide, setHide] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        if (!isShown) setHide(isShown);
      },
      isShown ? fadeDuration + fadeDelay : 0
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [phase]);

  return (
    <Loader hide={hide} isLoaded={isShown} fadeDuration={fadeDuration} fadeDelay={fadeDelay}>
      <Spinner>
        <Ring className={'black-one'} />
        <Ring className={'black-two'} />

        <div style={{ position: 'fixed', top: 'calc(50% - 30px)', left: 0, width: '100%', lineHeight: '20px' }}>
          <div>{event.name}</div>
          <div>{district ? district.title : '...'}</div>
          {phase === WarmupPhase.IDLE && <div>{'Init...'}</div>}
          {phase === WarmupPhase.LOADING && <div>{'Loading ' + Math.round(loadingProgress * 100) + '%'}</div>}
          {phase === WarmupPhase.ENTERING && <div>{'Entering ' + Math.round(enteringProgress * 100) + '%'}</div>}
          {phase === WarmupPhase.INIT && <div>{'Init'}</div>}
          {phase === WarmupPhase.DONE && <div>{'Done'}</div>}
        </div>
      </Spinner>
    </Loader>
  );
}
