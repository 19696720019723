import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePlayerStore } from 'services/PlayerService';
import AmbientSounds from '../AmbientSounds';
import { useHowlerStore } from 'services/HowlerService';
import { Howler } from 'howler';
import { useSoundStore } from 'services/SoundService';
import { useFrame } from '@react-three/fiber';
import { Vector2, Vector3 } from 'three';
import { useCameraStore } from 'services/CameraService';
import { useChatStore } from 'services/ChatService';
import { useUserStore } from 'services/UserService';
import { useContentStore } from 'services/ContentService';
import { useFrameAfterMount } from '../../../utilities/hooks';

const WalkingState = {
  FORWARD: 'FORWARD',
  BACKWARD: 'BACKWARD',
  STILL: 'STILL',
};

const attributes = {
  panningModel: 'HRTF',
  coneInnerAngle: 360,
  coneOuterAngle: 360,
  coneOuterGain: 0,
  distanceModel: 'exponential',
  maxDistance: 10,
  refDistance: 1,
  rolloffFactor: 2,
};

const PlayerSound = ({ playerId }) => {
  const [walkingState, setWalkingState] = useState(WalkingState.STILL);
  const walkingHowl = useRef(null);
  const walkingBkwdHowl = useRef(null);
  const player = usePlayerStore.getState().players[playerId];
  const reactionId = usePlayerStore(state => state.reaction);
  const isSelf = player.user.isSelf;
  const reactionSound = useHowlerStore(state => state.playEffectSounds);
  const isHowlerEnabled = useHowlerStore(state => state.isHowlerEnabled);
  const content = useContentStore(state => state.activeContent);
  const getEffectSound = useHowlerStore(state => state.getEffectSound);
  const createEffectSound = useHowlerStore(state => state.createEffectSound);

  useFrameAfterMount(() => {
    //change the global listener
    if (isSelf) {
      Howler.pos(player.position[0], player.position[1], player.position[2]);
      const orientation = new Vector3(0, 0, -1);
      orientation.applyQuaternion(useCameraStore.getState().quaternion);
      Howler.orientation(orientation.x, orientation.y, orientation.z, 0, 1, 0);
    }
    //detect if the player is walking
    const { position, velocity, rotation } = player;
    const vx = velocity[0];
    const vz = velocity[2];
    const speed = Math.sqrt(vx * vx + vz * vz);

    if (speed > 0.001) {
      const R = new Vector2(Math.sin(rotation), Math.cos(rotation));
      const V = new Vector2(vx / speed, vz / speed);
      const dotProduct = R.dot(V);
      if (dotProduct > 0) {
        if (walkingState !== WalkingState.FORWARD) setWalkingState(WalkingState.FORWARD);
      } else {
        if (walkingState !== WalkingState.BACKWARD) setWalkingState(WalkingState.BACKWARD);
      }
    } else {
      if (walkingState !== WalkingState.STILL) setWalkingState(WalkingState.STILL);
    }

    //set howl position depending of the player position
    walkingHowl.current.pos(position[0], position[1], position[2]);
    walkingBkwdHowl.current.pos(position[0], position[1], position[2]);
  });

  // play reaction sounds
  useEffect(() => {
    if (reactionId > 0) {
      isSelf && reactionSound(reactionId);
    }
  }, [reactionId]);

  //use this to when player is added / removed
  useEffect(() => {
    walkingHowl.current = createEffectSound('walkForward');
    walkingBkwdHowl.current = createEffectSound('walkBackward');
    walkingHowl.current.pannerAttr(attributes);
    walkingBkwdHowl.current.pannerAttr(attributes);
    return () => {
      walkingHowl.current.unload();
      walkingBkwdHowl.current.unload();
    };
  }, []);

  useEffect(() => {
    if (isHowlerEnabled) {
      if (walkingState === WalkingState.FORWARD) {
        walkingHowl.current.play();
        walkingBkwdHowl.current.stop();
      } else if (walkingState === WalkingState.BACKWARD) {
        walkingBkwdHowl.current.play();
        walkingHowl.current.stop();
      } else {
        walkingHowl.current.stop();
        walkingBkwdHowl.current.stop();
      }
    } else {
      walkingHowl.current.stop();
      walkingBkwdHowl.current.stop();
    }
  }, [walkingState, isHowlerEnabled]);

  //stop walking sound when video content is playing
  useEffect(() => {
    if (content) {
      walkingHowl.current.stop();
      walkingBkwdHowl.current.stop();
    }
  }, [content]);

  return null;
};

function PlayerSounds() {
  const playerIds = usePlayerStore(state => state.playerIds);
  return playerIds.map(playerId => {
    return <PlayerSound key={playerId} playerId={playerId} />;
  });
}

function EmojiSounds() {
  const emoji = useChatStore(state => state.emoji);
  const selfId = useUserStore(state => state.user.id);

  useEffect(() => {
    if (!emoji) return;
    if (emoji.userId !== selfId) return;
  }, [emoji, selfId]);

  return null;
}

function HowlerPlayer() {
  const enabledByUser = useSoundStore(state => state.enabled);
  const enabledBySystem = useSoundStore(state => state.isPlayAllowed);
  const enabled = enabledByUser && enabledBySystem;

  useEffect(() => {
    useHowlerStore.getState().setEnabled(enabled);
  }, [enabled]);

  return (
    <>
      {/* <EffectSounds /> */}
      <EmojiSounds />
      <AmbientSounds />
      <PlayerSounds />
    </>
  );
}

export default HowlerPlayer;
