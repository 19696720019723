import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { normalize } from 'styled-normalize';
// import { hot } from 'react-hot-loader';
import { textStyle, veFontFace, veFontStyle } from 'common/text';
import { hexToRgba } from 'utilities/color';

export const lgBaseColors = {
  white: '#FFF',
  grey: { veryLight: '#F7F7F5', light: '#A7A7A7', medium: '#6B6B6B', dark: '#191919' },
  red: { light: '#fbdbfc', base: '#7C73F3', highlight: '#E877AE' },
  yellow: { base: '#E28E33' },
  blue: { light: '#72D0E2' },
  green: { light: '#BAD80A', medium: '#62A60A', dark: '#293E40' },
};

export const colors = {
  font: { strong: 'white', light: lgBaseColors.grey.veryLight },
  background: { base: lgBaseColors.grey.dark, highlight: 'black' },
  accent: { base: lgBaseColors.red.base, highlight: lgBaseColors.red.highlight },
  secondary: { base: lgBaseColors.yellow.base },
  warn: lgBaseColors.red.highlight,
  error: lgBaseColors.red.base,
  success: lgBaseColors.green.medium,
};

export const veGradientLtR = css`
  linear-gradient(120deg, ${hexToRgba(colors.accent.base, 1)}, ${hexToRgba(colors.accent.highlight, 1)});
  -webkit-linear-gradient(120deg, ${hexToRgba(colors.accent.base, 1)}, ${hexToRgba(colors.accent.highlight, 1)});
`;

export const easings = {
  easeOutStrong: `cubic-bezier(0.12, 0.2, 0.09, 0.99)`,
};

const slideUpIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0px); }
`;

const fadeOut1 = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const animations = {
  showFromBottom: css`
    animation: ${slideUpIn} 0.15s ease forwards;
  `,
  fadeOut: css`
    animation: ${fadeOut1} 1s ease forwards;
  `,
};

const fullViewport = css`
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
`;

export const zIndex = {
  pins: [9999, 0],
  debug: 10100,
  modalAndNotifications: 10999,
  menu: 10017,
  overlayContent: 30000,
  chat: 30015,
  videoChat: 30016,
  loader: 30020,
  poweredBy: 40000,
  datGui: 40010,
};

export const chat = {
  defaultHeight: 360,
  textInputHeight: 62,
  titleHeight: 48,
};

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  body {
    ${veFontFace()};
    ${veFontStyle()};
    ${textStyle};
    word-break: break-word;

    position: relative;

    ${fullViewport};
    background-color: ${colors.background.base};
    user-select: none;
    overflow:hidden;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.05em;

    ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  * {
    touch-action: pan-x pan-y;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  .application {
    position: fixed;
    width: 100%;
    height: 100%;
  }
`;

export const center = {
  horizontal: () => css`
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  `,
  vertical: () => css`
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  `,
  absolute: () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  `,
};

export const menuTransitionDuration = 200;
export const sharedMenuButtonStyle = css`
  position: absolute;
  top: 0;
  ${props => {
    switch (props.align) {
      case 'LEFT':
        return 'left: 0';
      case 'RIGHT':
        return 'right: 0';
      default:
        return center.absolute();
    }
  }};
  width: 60px;
  height: 60px;
  background: none;
  z-index: ${zIndex.menu};
  color: white;
  text-align: center;
  cursor: pointer;
  transition: transform 0.15s ease;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 14px;

  > div {
    background: black;
    width: 2px;
    height: 25px;
  }
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const lightChatBackgroundStyle = css`
  background: ${colors.background.base};
`;
export const darkChatBackgroundStyle = css`
  background: ${props => (props.isContent ? lgBaseColors.grey.dark : colors.background.base)};
  color: ${props => (props.isContent ? colors.background.base : lgBaseColors.grey.dark)};
`;

export const baseBorderRadius = '10px';
